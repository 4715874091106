import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subject, Subscription } from 'rxjs';
import { AllUsersService, contributorData } from '@app/core/services/all-users.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { ErrorMessage, validateCountry } from '../edit-contributor/edit-contributor.component';
export let countryNames = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua &amp; Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia &amp; Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D Ivoire',
  'Croatia',
  'Cruise Ship',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Polynesia',
  'French West Indies',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Kyrgyz Republic',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Pierre &amp; Miquelon',
  'Samoa',
  'San Marino',
  'Satellite',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'St Kitts &amp; Nevis',
  'St Lucia',
  'St Vincent',
  'St. Lucia',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  "Timor L'Este",
  'Togo',
  'Tonga',
  'Trinidad &amp; Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks &amp; Caicos',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Uzbekistan',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (US)',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

@Component({
  selector: 'app-send-invitation',
  templateUrl: './send-invitation.component.html',
  styleUrls: ['./send-invitation.component.scss'],
})
export class SendInvitationComponent implements AfterViewInit, OnDestroy, AfterViewChecked {
  getAffiliationGroup() {
    return new UntypedFormGroup({
      affiliation: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', [Validators.required, validateCountry]),
    });
  }

  isAffiliationsValid(): boolean {
    if (this.roleSelect.value != 'author') {
      return true;
    }

    for (let i = 0; i < this.affiliations.length; i++) {
      const group = this.affiliations.at(i) as UntypedFormGroup;
      if (group.invalid) {
        return false;
      }
    }
    return true;
  }

  affiliationErrorMessages: ErrorMessage[] = [
    { type: 'required', message: 'Affiliation is required.' },
  ];

  cityErrorMessages: ErrorMessage[] = [{ type: 'required', message: 'City is required.' }];

  countryErrorMessages: ErrorMessage[] = [
    { type: 'invalidValue', message: 'Country not recognized.' },
    { type: 'required', message: 'Country is required.' },
  ];

  filter(val: string) {
    return countryNames.filter((y: string) => y.toLowerCase().startsWith(val.toLowerCase()));
  }

  usersChipList = new UntypedFormControl('', Validators.required);
  notifyingPeople = new UntypedFormControl('', Validators.required);
  roleSelect = new UntypedFormControl('author', Validators.required);
  affiliations = new UntypedFormArray([this.getAffiliationGroup()]);
  message = new UntypedFormControl('Invitation message.');
  isCoAuthor = new UntypedFormControl(false);

  inviteUsersForm: any = new UntypedFormGroup({
    usersChipList: this.usersChipList,
    notifyingPeople: this.notifyingPeople,
    roleSelect: this.roleSelect,
    affiliations: this.affiliations,
    message: this.message,
    isCoAuthor: this.isCoAuthor,
  });

  removeAffiliation(index: number) {
    this.affiliations.removeAt(index);
  }

  addAffiliation() {
    this.affiliations.push(this.getAffiliationGroup());
  }

  separatorKeysCodes: number[] = [ENTER, COMMA];
  invitedPeople = new UntypedFormControl('');
  filteredInvitedPeople: Observable<contributorData[]>;
  users: contributorData[] = [];
  searchData: contributorData[];
  @ViewChild('usersInput') usersInput: ElementRef<HTMLInputElement>;

  isLoading = true;

  // accessOptions: any[] = [
  //   {
  //     name: 'Reader'
  //   },
  //   {
  //     name: 'Commenter'
  //   },
  //   {
  //     name: 'Writer'
  //   },
  // ]

  roleOptions: any[] = [
    {
      name: 'Author',
    },
    {
      name: 'Corresponding author',
    },
    {
      name: 'Contributor',
    },
  ];

  resultData = new Subject<contributorData[]>();

  constructor(
    private location: Location,
    private serviceShare: ServiceShare,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SendInvitationComponent>,
    public allUsersService: AllUsersService,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.invitedPeople.valueChanges.subscribe((value) => {
      this.allUsersService
        .getAllUsersV2({ page: 1, pageSize: 10, 'filter[search]': value })
        .subscribe((val: any) => {
          if (val.meta.filter && val.meta.filter.search) {
            this.resultData.next(
              val.data.filter((user) => {
                return (
                  !this.collaborators.collaborators.find((col) => {
                    if (col.id) return col.id == user.id;
                    if (user.email) return col.email == user.email;
                  }) &&
                  !this.users.find((col) => {
                    if (col.id) return col.id == user.id;
                    if (user.email) return col.email == user.email;
                  })
                );
              })
            );
          } else {
            this.resultData.next([]);
          }
        });
    });
    this.allUsersService.getCollaboratorsRoles().subscribe({
      next: (res: any) => {
        this.roleOptions = res.data.filter((r: any) => !r.is_hidden);
        this.isLoading = false;
        setTimeout(() => {
          if (!this.dialogIsOpenedFromComment) {
            this.usersInput.nativeElement.focus();
          }
        }, 10);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  add(event: MatChipInputEvent): void {
    if (event.value) {
      //this.users.push({ email: event.value, name: '' });
    }

    // Clear the input value
    event.chipInput!.clear();

    this.invitedPeople.setValue(null);
  }

  backClicked() {
    this.location.back();
  }

  remove(deluser: contributorData): void {
    const index = this.users.findIndex((user) => user.email == deluser.email);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.users.push(event.option.value);
    this.usersInput.nativeElement.value = '';
    this.invitedPeople.setValue(null);
  }

  private _filter(value: string | contributorData): contributorData[] {
    let filterValue;
    if (typeof value == 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.email.toLowerCase();
    }
    return this.searchData.filter(
      (user) =>
        user.email.toLowerCase().includes(filterValue) &&
        !this.users.find((data) => data.email == user.email)
    );
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  // doAction(data: any) {
  //   this.dialogRef.close({ data});
  // }
  submitInviteUsersForm() {
    this.inviteUsersForm.controls.usersChipList.setValue(this.users);
    let usersDataCopy = JSON.parse(JSON.stringify(this.inviteUsersForm.value));
    usersDataCopy.affiliations = usersDataCopy.affiliations.filter((x) => {
      return !(
        (!x.affiliation || x.affiliation.length == 0) &&
        (!x.country || x.country.length == 0) &&
        (!x.city || x.city.length == 0)
      );
    });
    if (this.roleSelect.value != 'author' && this.roleSelect.value != 'author_commenter') {
      usersDataCopy.isCoAuthor = false;
    }
    this.dialogRef.close(usersDataCopy);
  }
  dialogIsOpenedFromComment = false;
  collaboratorstSubs: Subscription;

  ngAfterViewInit(): void {
    this.users.push(...this.data.contributor);
    if (this.data.fromComment) {
      this.dialogIsOpenedFromComment = true;
    }
    this.collaboratorstSubs = this.serviceShare.YdocService.collaboratorsSubject.subscribe(
      (data) => {
        this.setCollaboratorsData(data);
      }
    );
    this.setCollaboratorsData(this.serviceShare.YdocService.collaborators.get('collaborators'));
    this.ref.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  collaborators;
  setCollaboratorsData(collaboratorsData: any) {
    setTimeout(() => {
      this.collaborators = collaboratorsData;
    }, 30);
  }

  ngOnDestroy(): void {
    if (this.collaboratorstSubs) {
      this.collaboratorstSubs.unsubscribe();
    }
  }
}
