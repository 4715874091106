import { AuthModel } from '@core/models/auth.model';

export class UserModel extends AuthModel {
  id!: string;
  username!: string;
  password!: string;
  fullname!: string;
  email!: string;
  pic?: string;
  name?: string;
  role?: any;

  constructor(user: Partial<UserModel>) {
    super(
      new AuthModel({ accessToken: user.accessToken, refreshToken: user.accessToken } as AuthModel)
    );
    Object.assign(this, { ...user });
  }
}
