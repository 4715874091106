import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MarkType } from 'prosemirror-model';
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

import { AddLinkDialogComponent } from '@app/editor/add-link-dialog/add-link-dialog.component';
import { ServiceShare } from '@app/editor/services/service-share.service';

@Injectable({
  providedIn: 'root',
})
export class LinkButtonsService {
  linkButtonsPluginKey = new PluginKey('linkButtonsPlugin');
  linkButtonsPlugin: Plugin;

  linkButtonsClasses = ['edit-link-button', 'unlink-button'];

  constructor(
    private dialog: MatDialog,
    private serviceShare: ServiceShare
  ) {
    this.serviceShare.shareSelf('LinkButtonsService', this);
    const self = this;

    this.linkButtonsPlugin = new Plugin({
      key: this.linkButtonsPluginKey,
      state: {
        init: (config: any, _: EditorState) => {
          return { sectionName: config.sectionName };
        },
        apply: (transaction: Transaction, value, _, newState) => {
          return value;
        },
      },
      props: {
        handleDOMEvents: {
          blur: (view, event) => {
            if (
              event.relatedTarget &&
              event.relatedTarget instanceof HTMLButtonElement &&
              this.linkButtonsClasses.includes(event.relatedTarget.className)
            ) {
              event.relatedTarget.click();
            }
          },
        },
      },
      view: function (view: EditorView) {
        return {
          update: (view: EditorView, prevState) => {
            if (
              JSON.stringify(view.state.doc) == JSON.stringify(prevState.doc) &&
              !view.hasFocus()
            ) {
              return;
            }

            self.attachButtons(view);
          },
          destroy: () => {},
        };
      },
    });
  }

  attachButtons(view: EditorView) {
    const state = view.state;
    const anchor = state.selection.$anchor;
    const linkMarkInfo = this.markPosition(state, anchor.pos, state.schema.marks.link);
    const { from, to } = view.state.selection;

    const btnsWrapper = document.getElementsByClassName(
      'editor_buttons_wrapper'
    )[0] as HTMLDivElement;
    if (!btnsWrapper) return;
    const editButtonContainer = btnsWrapper.getElementsByClassName(
      'edit-link-btn-container'
    )[0] as HTMLDivElement;
    const unlinkButtonContainer = btnsWrapper.getElementsByClassName(
      'delete-link-btn-container'
    )[0] as HTMLDivElement;

    if (!editButtonContainer || !unlinkButtonContainer) return;

    if (linkMarkInfo && from == to) {
      editButtonContainer.style.display = 'block';
      unlinkButtonContainer.style.display = 'block';

      const editButton = editButtonContainer.getElementsByClassName(
        'edit-link-button'
      )[0] as HTMLButtonElement;
      const unlinkButton = unlinkButtonContainer.getElementsByClassName(
        'unlink-button'
      )[0] as HTMLButtonElement;
      editButton.title = 'Edit link.';

      editButton.removeAllListeners!('click');
      unlinkButton.removeAllListeners!('click');
      editButton.addEventListener('click', (event: MouseEvent) => {
        const { href, title } = linkMarkInfo.mark.attrs;

        this.dialog
          .open(AddLinkDialogComponent, {
            width: '582px',
            panelClass: 'insert-figure-in-editor',
            data: { url: href, text: title },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result && result.url && result.text) {
              const { url, text } = result;
              const { from, to, mark: oldMark } = linkMarkInfo;

              const newMark = state.schema.marks.link.create({
                href: url,
                title: text,
              });

              state.tr.removeMark(from, to, oldMark);
              const newTextNode = state.schema.text(text, [newMark]);
              const tr = state.tr.replaceRangeWith(from, to, newTextNode);
              view.dispatch(tr);
            }
          });
      });

      unlinkButton.addEventListener('click', () => {
        const { from, to, mark } = linkMarkInfo;

        const tr = state.tr.removeMark(from, to, mark);
        view.dispatch(tr);
      });
    } else {
      editButtonContainer.style.display = 'none';
      unlinkButtonContainer.style.display = 'none';
    }
  }

  markPosition(state: EditorState, pos: number, markType: MarkType) {
    const $pos = state.doc.resolve(pos);
    //@ts-ignore
    const path = $pos.path;
    const isSupplementary = path.find(
      (node) => node?.type && node.type.name === 'supplementary-file-url'
    );
    if (isSupplementary) return;

    const { parent, parentOffset } = $pos;
    if (parent?.type.name == 'reference_citation_end') return;

    const { node, offset } = parent.childAfter(parentOffset);
    if (!node) return;

    const mark = node.marks.find((mark) => mark.type === markType);
    if (!mark || mark?.attrs?.controlPath?.includes('externalLinks')) return;

    let from = $pos.start() + offset;
    let to = from + node.nodeSize;

    return { from, to, mark };
  }
}
