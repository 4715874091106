import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  ChangeDetectorRef,
  Input,
  TemplateRef,
  EmbeddedViewRef,
  ViewContainerRef,
  AfterContentInit,
} from '@angular/core';
import { ServiceShare } from '@app/editor/services/service-share.service';

@Directive({
  selector: '[appPermissions]',
})
export class PermissionsDirective implements AfterContentInit {
  private hasView = false;
  private isChecked = false;
  private condition = false;

  private hasPerrmission = false;

  obj: string;
  act: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sharedService: ServiceShare,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngAfterContentInit(): void {
    this.subscribeForPermissions();
  }

  @Input() set appPermissions(condition: boolean) {
    this.condition = condition;
    if (this.obj) {
      this.updateView();
    }
  }

  @Input() set attribute(value: string) {
    // console.log('Attribute value:', value);
    this.obj = value;
    this.updateView();
  }

  @Input() set action(value: string) {
    // console.log('Attribute value:', value);
    this.act = value;
    this.updateView();
  }

  private updateView(): void {
    if (this.condition && !this.hasView) {
      if (this.isChecked) {
        this.addViewOrClearView();
      } else {
        this.checkForPersission();
      }
    } else if (!this.condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  checkForPersission() {
    this.sharedService.EnforcerService.enforceAsync(this.obj, this.act).subscribe(
      (hasPermission: boolean) => {
        this.hasPerrmission = hasPermission;
        this.addViewOrClearView();

        this.isChecked = true;
      }
    );
  }

  subscribeForPermissions() {
    this.sharedService.EnforcerService.newBeahviorSubject.subscribe((data) => {
      // debugger
      if (data == 'updated_policies') {
        this.sharedService.EnforcerService.enforceAsync(this.obj, this.act).subscribe(
          (hasPermission: boolean) => {
            // console.log(hasPermission);
            this.hasPerrmission = hasPermission;
            this.addViewOrClearView();
            this.changeDetector.detectChanges();
          }
        );
      }
    });
  }

  addViewOrClearView() {
    // debugger
    if (this.hasPerrmission && this.condition) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
    this.changeDetector.detectChanges();
  }
}
