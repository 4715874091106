import { Injectable } from '@angular/core';
import { keyMatchFunc } from 'casbin/lib/cjs/util';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { GlobalObjContainer } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CasbinGlobalObjectsService {
  constructor(private sharedService: ServiceShare) {
    this.sharedService.shareSelf('CasbinGlobalObjectsService', this);
  }

  ReferenceItem: GlobalObjContainer = {
    items: {},
    isOwner: (robj, ...args) => {
      // check if there is arg that maches with requested obj
      const matched = args.some((arg) => keyMatchFunc(robj, `${arg}`));

      if (!matched) return false;

      const reqObj = robj;
      const currUserId = this.sharedService.EnforcerService.userInfo.id;

      const reqObjData = reqObj.split('/');
      let objId = reqObjData[reqObjData.length - 1];
      let ref = this.ReferenceItem.items[objId];
      let refOwnerId = ref.user.id;
      return refOwnerId == currUserId;
    },
  };

  isOwner(robj, ...args) {
    return true;
  }

  comments(args: any[]) {
    if (typeof this[args[0]] == 'function') {
      this[args[0]]();

      return true;
    } else {
      const matched = args.some((arg) =>
        keyMatchFunc(this.sharedService.YdocService.articleData?.uuid, `${arg}`)
      );

      return matched;
    }
  }

  taxons(args: any[]) {
    // console.log("taxons(args: any[]) {");

    const matched = args?.some((arg) =>
      keyMatchFunc(this.sharedService.YdocService.articleData?.uuid, `${arg}`)
    );

    return matched;
  }

  sections(args: any[]) {
    // console.log("sections(вargs: any[]) {");

    const matched = args?.some((arg) =>
      keyMatchFunc(this.sharedService.YdocService.articleData?.uuid, `${arg}`)
    );

    return matched;
  }

  editMode(args: any[]) {
    console.log('editMode(args: any[]) {');

    const matched = args?.some((arg) =>
      keyMatchFunc(this.sharedService.YdocService.articleData?.uuid, `${arg}`)
    );

    return matched;
  }

  versions(args: any[]) {
    // console.log("versions(args: any[]) {");

    const matched = args?.some((arg) =>
      keyMatchFunc(this.sharedService.YdocService.articleData?.uuid, `${arg}`)
    );

    return matched;

    // console.log(this.sharedService.YdocService.userInfo?.data?.ro);
    // return this.sharedService.YdocService.userInfo?.data?.role;
  }

  contributors(args: any[]) {
    return true;
  }

  isUserVisibleForMe(args: any[]) {
    console.log(this.sharedService.ProsemirrorEditorsService.userInfo);

    return true;
  }

  isCommentsVisibleForMe(args: any[]) {
    return true;
  }

  addItemToGlobalContainer(glContainerKey: string, objId: string, obj: any) {
    this[glContainerKey].items[objId] = obj;
  }

  actionButtons() {
    return true;
  }
}
