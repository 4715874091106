import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TreeService } from '@app/editor/meta-data-tree/tree-service/tree.service';
import { articleSection } from '@app/editor/utils/interfaces/articleSection';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';

import * as XLSX from 'xlsx';
import Papa from 'papaparse';

import { uuidv4 } from 'lib0/random';
import { material } from '@core/services/custom_sections/material';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { EditSectionDialogComponent } from '@app/editor/dialogs/edit-section-dialog/edit-section-dialog.component';
import { FormBuilderService } from '@app/editor/services/form-builder.service';
import { HelperService } from '@app/editor/section/helpers/helper.service';
import { customSecInterface } from '../funder-section/funder-section.component';
import { CsvServiceService } from '@app/editor/csv-service/csv-service.service';
import { LinkPopUpPluginServiceService } from '@app/editor/utils/linkPopUpPlugin/link-pop-up-plugin-service.service';
import { AskBeforeDeleteComponent } from '@app/editor/dialogs/ask-before-delete/ask-before-delete.component';
import { CdkDragDrop, CdkDropList, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/shared/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialSectionComponent } from '../material-section/material-section.component';
import { IntersectionObserverDirective } from '@app/editor/directives/intersection-observer.directive';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';

@Component({
  selector: 'app-materials-section',
  templateUrl: './materials-section.component.html',
  styleUrls: ['./materials-section.component.scss'],
})
export class MaterialsSectionComponent implements OnInit, OnDestroy, customSecInterface {
  @Input() onSubmit!: (data: any) => Promise<any>;
  @Output() onSubmitChange = new EventEmitter<(data: any) => Promise<any>>();

  @Input() section!: articleSection;
  @Input() fGroup!: UntypedFormGroup;
  @Output() sectionChange = new EventEmitter<articleSection>();

  @Input() triggerCustomSecSubmit: Subject<any>;
  @Output() triggerCustomSecSubmitChange = new EventEmitter<Subject<any>>();

  @ViewChild('dragDropList', { read: CdkDropList }) dragDropList?: CdkDropList;

  importMaterialData!: UntypedFormControl;
  placeMultiple!: UntypedFormControl;
  placeMultipleRadio!: UntypedFormControl;
  init = true;
  render = false;
  isLoading = false;
  materials = [];
  types = ['Holotype', 'Syntype', 'Hapantotype', 'Paratype', 'Other material'];
  typeControl = new UntypedFormControl('Holotype');
  materialsSubscription: Subscription;
  processing = false;
  totalMaterials: number;
  processedMaterials = 0;
  progress = 0;
  tooltipContent = `
    The first row contains column labels that match exactly the Darwin Core terms (see template).
    The content of the column corresponds to its label.
    Each occurrence record is in a separate row.
    The column typeStatus contains one of the following (without the quotes):
    'Other material', 'Holotype', 'Paratype', 'Hapantotype', 'Syntype', 'Isotype', 'Neotype',
    'Lectotype', 'Paralectotype', 'Isoparatype', 'Isolectotype', 'Isoneotype', 'Isosyntype'.
  `;

  constructor(
    private treeService: TreeService,
    public http: HttpClient,
    public serviceShare: ServiceShare,
    public dialogRef: MatDialogRef<MaterialsSectionComponent>,
    public dialog: MatDialog,
    public formBuilderService: FormBuilderService,
    public helperService: HelperService,
    private csvService: CsvServiceService,
    private linkPopUp: LinkPopUpPluginServiceService,
    private snackBar: SnackbarService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  tabs: string[];
  props: string[];

  ngOnInit(): void {
    const customPropsObj = this.serviceShare.YdocService.customSectionProps.get('customPropsObj');
    this.section.children.forEach((ch: articleSection) => {
      // const material = this.serviceShare.YdocService.getSectionByID(ch.sectionID);
      this.materials.push({
        ...customPropsObj[ch.sectionID],
        sectionID: ch.sectionID,
      });
    });
    this.importMaterialData = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('importMaterialData')?.value
    );
    this.placeMultiple = new UntypedFormControl('');
    this.placeMultipleRadio = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('placeMultipleRadio')?.value
    );
    this.render = true;

    this.triggerCustomSecSubmit.subscribe(() => {
      this.triggerSubmit();
    });

    this.tabs = Object.keys(this.section.originalSectionTemplate.schema.override.categories);
    this.props = this.tabs
      .map((key: string) => {
        return this.section.originalSectionTemplate.schema.override.categories[key].entries.map(
          (entry: any) => entry.localName
        );
      })
      .flat();
  }

  @Output() async triggerSubmit() {
    let data: any = {
      importMaterialData: this.importMaterialData!.value,
      placeMultiple: this.placeMultiple!.value,
      placeMultipleRadio: this.placeMultipleRadio!.value,
    };
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined || data[key] === null) {
        delete data[key];
      }
    });
    await this.onSubmit({ data });
  }

  addManually() {
    let sectionContent = this.formBuilderService.populateDefaultValues(
      {},
      this.section.formIOSchema,
      this.section.sectionID,
      this.section
    );
    const section = material;
    section.mode = 'editMode';
    section['originalSectionTemplate'] = {};
    section.originalSectionTemplate.parentId = this.section.sectionID;
    section['title'] = {};
    section.title.name = 'Material';

    this.dialog
      .open(EditSectionDialogComponent, {
        width: '95%',
        height: '90%',
        data: {
          node: section,
          form: this.fGroup || new UntypedFormGroup({}),
          sectionContent,
          component: '[MM] Material',
        },
        disableClose: false,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.data) {
          this.addMaterialData([result.data]);
        }
      });
    this.dialogRef.close();
  }

  addJson() {
    this.isLoading = true;
    const convertedData = JSON.parse(this.placeMultiple!.value);
    this.addMaterialData(convertedData);
  }

  addFromExternalSource() {
    let ids: string = this.placeMultiple!.value;
    let idType: string = this.placeMultipleRadio!.value;

    if (ids && ids !== '' && idType) {
      const options = {
        boldId: 'boldid',
        boldBin: 'boldbin',
        gbifViaId: 'gbifoccurrid',
        gbifId: 'gbifid',
        iDigBioUUID: 'idigbio',
      };
      let option = options[idType];

      if (option) {
        this.isLoading = true;
        this.materialsSubscription = this.http
          .get(`${this.config.taxonSearch}/materials/${option}/${ids}`)
          .subscribe({
            next: (response: any) => {
              this.isLoading = false;
              this.addMaterialData(response);
            },
            error: (error: any) => {
              this.isLoading = false;
              console.error(error);
            },
          });
      }
    }
  }

  async onFileSelected(event: any) {
    this.isLoading = true;

    if (!event.target.files.length) {
      this.isLoading = false;
      return;
    }

    if (event.target.files[0].type == 'text/csv') {
      Papa.parse(event.target.files[0], {
        worker: true,
        delimiter: ';',
        complete: (results) => {
          const convertedData = [];
          for (let i = 1; i < results.data.length; i++) {
            if (
              !(
                results.data[i].length == 0 ||
                results.data[i].reduce((prev, curr) => {
                  return prev + curr;
                }, '').length == 0
              )
            ) {
              const data = {};
              results.data[0].map((item, index) => {
                if (results.data[i][index]) {
                  if (item != 'typeStatus' && results.data[i][index] && this.props.includes(item)) {
                    data[item] = results.data[i][index] + ';&nbsp;';
                  } else if (results.data[i][index] && this.props.includes(item)) {
                    data[item] = results.data[i][index];
                  }
                }
              });
              if (Object.keys(data).length) convertedData.push(data);
            }
          }
          if (convertedData.length) {
            this.isLoading = false;
            this.addMaterialData(convertedData);
          } else {
            this.invalidFile();
          }
        },
      });
    } else if (event.target.files[0].type == 'application/vnd.ms-excel') {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        const arrayBuffer = fileReader.result;

        //@ts-ignore
        const data = new Uint8Array(arrayBuffer);
        const arr = new Array();
        for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        const bstr = arr.join('');
        const workbook = XLSX.read(bstr, { type: 'binary' });

        const worksheet_name_list = workbook.SheetNames;
        const convertedData = XLSX.utils.sheet_to_json(workbook.Sheets[worksheet_name_list[0]]);

        const result = [];
        convertedData.forEach((data) => {
          const obj = {};
          Object.keys(data).forEach((key) => {
            if (key != 'typeStatus' && data[key] && this.props.includes(key)) {
              obj[key] = data[key] + ';&nbsp;';
            } else if (data[key] && this.props.includes(key)) {
              obj[key] = data[key];
            }
          });
          if (Object.keys(obj).length) result.push(obj);
        });
        if (result.length) {
          this.isLoading = false;
          this.addMaterialData(result);
        } else {
          this.invalidFile();
        }
      };
      fileReader.readAsArrayBuffer(event.target.files[0]);
    }
  }

  invalidFile() {
    this.snackBar.error('Invalid file! Please import file with correct data.');
    this.isLoading = false;
  }

  async addMaterialData(convertedData: any[]): Promise<void> {
    if (convertedData.length === 0) {
      this.dialogRef.close();
    }

    this.processing = true;
    this.totalMaterials = convertedData.length;
    const sectionsData = [];

    for (const row of convertedData) {
      const materialData = JSON.parse(JSON.stringify(material));
      materialData.mode = 'noSchemaSectionMode';
      materialData.active = false;
      materialData.defaultFormIOValues = row;
      materialData.sectionID = uuidv4();
      materialData.label = row.typeStatus;
      materialData.parentId = this.section.sectionID;
      sectionsData.push(materialData);
      this.processedMaterials = sectionsData.length;
      this.progress = (this.processedMaterials / this.totalMaterials) * 100;

      await new Promise((resolve) => setTimeout(resolve, 10));
    }

    const { data, nodeForm } = this.treeService.orderMaterialSections(
      [
        ...this.section.children.map(
          (ch) => this.serviceShare.YdocService.getSectionByID(ch.sectionID).originalSectionTemplate
        ),
        ...sectionsData,
      ],
      new UntypedFormGroup({})
    );
    this.section.defaultFormIOValues = data;
    this.serviceShare.TreeService!.addNodeAtPlaceChange(
      this.section.sectionID,
      this.section,
      'end',
      sectionsData,
      nodeForm
    );
    this.dialogRef.close();
  }

  downloadMaterials(event: Event) {
    event.preventDefault();
    if (this.checked.length > 0) {
      const text = this.csvService.arrayToCSV(this.section.sectionID, this.checked);
      const fileName = 'csv-materials.csv';
      this.linkPopUp.download(fileName, text, false);
    }
  }

  deleteAllCheckedMaterials() {
    if (this.checked.length > 0) {
      this.dialog
        .open(AskBeforeDeleteComponent, {
          data: {
            type: 'materials',
            dontshowType: true,
            objName: 'all Materials',
          },
          panelClass: 'ask-before-delete-dialog',
        })
        .afterClosed()
        .subscribe((data: any) => {
          if (data) {
            this.treeService.deleteAllCheckedMaterials(this.section, this.checked);
            this.materials = this.materials.filter(
              (material) => !this.checked.find((m: any) => m.sectionID == material.sectionID)
            );
          }
        });
    }
  }

  getKeys(obj: any) {
    return Object.keys(obj);
  }

  checked = [];

  checkboxClick(sectionID: string) {
    const material = this.checked.find((m) => m.sectionID == sectionID);

    if (material) {
      this.checked = this.checked.filter((m) => m.sectionID != sectionID);
    } else {
      this.checked.push(this.materials.find((m) => m.sectionID == sectionID));
    }
  }

  isChecked(sectionID: string) {
    return this.checked.find((m) => m.sectionID == sectionID);
  }

  allAreSelected = false;
  selectAll() {
    this.allAreSelected = !this.allAreSelected;

    if (this.allAreSelected) {
      this.checked = this.materials;
    } else {
      this.checked = [];
    }
  }

  changeTypesOnSelected() {
    const materials = [];

    this.section.children.forEach((section) => {
      const material = this.checked.find((s) => s.sectionID == section.sectionID);

      if (material) {
        section.originalSectionTemplate.defaultFormIOValues.typeStatus = this.typeControl.value;
        this.materials[
          this.materials.findIndex((m) => m.sectionID == material.sectionID)
        ].typeStatus = this.typeControl.value;
      }
      materials.push(section);
    });
    this.treeService.editMultipleMaterials(materials, this.section.sectionID);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.materials, event.previousIndex, event.currentIndex);
    const children = [];

    this.materials.forEach((m) => {
      children.push(this.section.children.find((ch) => ch.sectionID == m.sectionID));
    });

    const materialData = { parent: this.section, children };
    this.treeService.dragNodeChange(
      event.previousIndex,
      event.currentIndex,
      this.section.sectionID,
      this.section.sectionID,
      this.section,
      materialData
    );
    this.treeService.applyNodeDrag(
      event.previousIndex,
      event.currentIndex,
      this.section.sectionID,
      this.section.sectionID,
      materialData
    );
  }

  editMaterial(sectionID: string) {
    let sectionContent = this.formBuilderService.populateDefaultValues(
      {},
      this.section.formIOSchema,
      this.section.sectionID,
      this.section
    );
    const material = this.serviceShare.YdocService.getSectionByID(sectionID);
    material.originalSectionTemplate.parentId = this.section.sectionID;
    this.dialog
      .open(EditSectionDialogComponent, {
        width: '95%',
        height: '90%',
        data: {
          node: this.serviceShare.YdocService.getSectionByID(sectionID),
          form: this.fGroup || new UntypedFormGroup({}),
          sectionContent,
          component: '[MM] Material',
        },
        disableClose: false,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.data) {
          this.treeService.editMaterial(material, result.data);
        }
      });
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.materialsSubscription) {
      this.materialsSubscription.unsubscribe();
    }
  }
}

@NgModule({
  declarations: [
    MaterialsSectionComponent,
    MaterialSectionComponent,
    IntersectionObserverDirective,
  ],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, ReactiveFormsModule, DragDropModule],
  exports: [MaterialsSectionComponent, MaterialSectionComponent, IntersectionObserverDirective],
})
export class MaterialsModule {}
