import { Injectable } from '@angular/core';
import { convertArrayToCSV } from 'convert-array-to-csv';
import * as XLSX from 'xlsx';

import { YdocService } from '@app/editor/services/ydoc.service';
import { HelperService } from '@app/editor/section/helpers/helper.service';
import { TreeService } from '@app/editor/meta-data-tree/tree-service/tree.service';
import { articleSection } from '../utils/interfaces/articleSection';

@Injectable({
  providedIn: 'root',
})
export class CsvServiceService {
  articleSectionsStructure: any;

  constructor(
    private ydocService: YdocService,
    public helperService: HelperService,
    public treeService: TreeService
  ) {}

  findNestedObj(entireObj: any, keyToFind: string, valToFind: string) {
    let foundObj: any;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  }

  arrayToCSV(sectionId: string, checkedMaterials?: any[]) {
    const { parent, props } = this.getData(sectionId);

    const dataArrays = [];
    parent.children.forEach((item: any) => {
      const addToArr = () => {
        const cloned = JSON.parse(JSON.stringify(item.defaultFormIOValues));
        const row = new Array(props.length).fill('');
        Object.keys(cloned).forEach((key: string) => {
          const index = props.indexOf(key);
          if (index > -1) {
            row[index] = cloned[key];
          }
        });
        dataArrays.push(row);
      };

      if (checkedMaterials && checkedMaterials.find((m: any) => m.sectionID == item.sectionID)) {
        addToArr();
      } else if (!checkedMaterials) {
        addToArr();
      }
    });
    const header = props;
    const csvFromArrayOfArrays = convertArrayToCSV(dataArrays, {
      header,
      separator: ';',
    });
    return csvFromArrayOfArrays;
  }

  exportToXLSX(sectionId: string, fileName: string = 'export.xls'): void {
    const { parent, props } = this.getData(sectionId);

    const dataArrays = [];
    parent.children.forEach((item: any) => {
      const cloned = JSON.parse(JSON.stringify(item.defaultFormIOValues));
      const row = {};
      props.forEach((prop: string) => (row[prop] = ''));
      Object.keys(cloned).forEach((key: string) => {
        if (props.includes(key)) {
          row[key] = cloned[key];
        }
      });
      dataArrays.push(row);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataArrays);

    const colWidth = dataArrays.map((row: any) =>
      Object.keys(row).map((key: string) =>
        key.length > row[key].length ? key.length : row[key].length
      )
    );
    const maxColWidth = colWidth[0].map((_, i) => Math.max(...colWidth.map((row) => row[i])));
    ws['!cols'] = maxColWidth.map((w) => ({ wch: w }));

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout: Blob = new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'array' })]);

    const url = URL.createObjectURL(wbout);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  getData(sectionId: string): { parent: articleSection; props: string[] } {
    const parent = this.ydocService.articleSectionsMap.get(sectionId);
    const props = Object.keys(parent.originalSectionTemplate.schema.override.categories)
      .map((key: string) => {
        return parent.originalSectionTemplate.schema.override.categories[key].entries.map(
          (entry: any) => {
            return entry.localName;
          }
        );
      })
      .flat();
    props.unshift('typeStatus');

    return { parent, props };
  }
}
