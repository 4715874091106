import { editorContainersObj } from '@app/editor/services/prosemirror-editors.service';
import { EditorView } from 'prosemirror-view';
import { commentMarkNames } from './comments.service';

export function checkAllEditorsIfMarkOfCommentExists(
  edContainers: editorContainersObj,
  commentId: string
): boolean {
  let exists = false;
  Object.keys(edContainers).forEach((sectionid) => {
    let view = edContainers[sectionid].editorView;
    if (checkEditorIfMarkOfCommentExists(view, commentId)) {
      exists = true;
    }
  });
  return exists;
}

export function checkEditorIfMarkOfCommentExists(view: EditorView, commentId: string): boolean {
  let doc = view.state.doc;
  let docend = doc.content.size;
  let exists = false;
  doc.nodesBetween(0, docend, (node, pos, parent, i) => {
    const marks = node.marks.filter((mark) => commentMarkNames.includes(mark.type.name));
    if (marks.length > 0 && marks.find((m) => m.attrs.id == commentId)) {
      exists = true;
    }
  });
  return exists;
}

export interface userDataInComment {
  created_at: string;
  email: string;
  email_verified_at: string;
  id: String;
  name: string;
  userColor: string;
  userContrastColor: string;
  updated_at: string;
}

export interface ydocComment {
  comment: string;
  date: number;
  id: string;
  userData: userDataInComment;
  pmDocStartPos?: number;
  pmDocEndPos?: number;
  commentTxt?: string;
  commentId?: string;
  sectionId?: string;
  connectedTo?: string[];
}

export interface commentYdocSave {
  commentReplies: ydocComment[];
  initialComment: ydocComment;
}
export interface ydocCommentsObj {
  [key: string]: commentYdocSave;
}

export interface commentData {
  pmDocStartPos: number;
  pmDocEndPos: number;
  domTop: number;
  commentTxt: string;
  section: string;
  commentAttrs: any;
  commentMarkId: string;
  selected: boolean;
  threadComments: commentData[];
  resolved?: string;
}
