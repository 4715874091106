import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { countryNames } from '../send-invitation/send-invitation.component';
import { ConfirmationDialogComponent } from '@app/editor/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AllUsersService } from '@app/core/services/all-users.service';

export type ErrorMessage = {
  type: string;
  message: string;
};

export function validateCountry(control: AbstractControl): { [key: string]: any } | null {
  if (!control.value) return null;
  if (countryNames.indexOf(control.value) !== -1) {
    return null;
  }
  return { invalidValue: true };
}

@Component({
  selector: 'app-edit-contributor',
  templateUrl: './edit-contributor.component.html',
  styleUrls: ['./edit-contributor.component.scss'],
})
export class EditContributorComponent implements OnInit, AfterViewChecked {
  getAffiliationGroup(data?: any) {
    return new UntypedFormGroup({
      affiliation: new UntypedFormControl(data ? data.affiliation : '', Validators.required),
      city: new UntypedFormControl(data ? data.city : '', Validators.required),
      country: new UntypedFormControl(data ? data.country : '', [
        Validators.required,
        validateCountry,
      ]),
    });
  }

  filter(val: string) {
    return countryNames.filter((y: string) => y.toLowerCase().startsWith(val.toLowerCase()));
  }

  email = new UntypedFormControl(this.data?.contrData?.email || '', Validators.required);
  firstName = new UntypedFormControl(this.data?.contrData?.first_name || '', Validators.required);
  lastName = new UntypedFormControl(this.data?.contrData?.last_name || '', Validators.required);
  roleSelect = new UntypedFormControl('author', Validators.required);
  affiliations = new UntypedFormArray([]);
  isCoAuthor = new UntypedFormControl(this.data?.contrData?.is_co_author);
  isOwner = false;

  editUserForm: any = new UntypedFormGroup({
    email: this.email,
    firstName: this.firstName,
    lastName: this.lastName,
    roleSelect: this.roleSelect,
    isCoAuthor: this.isCoAuthor,
    affiliations: this.affiliations,
  });

  isLoading = true;

  roleOptions: any[] = [
    {
      name: 'Author',
    },
    {
      name: 'Corresponding author',
    },
    {
      name: 'Contributor',
    },
  ];

  affiliationErrorMessages: ErrorMessage[] = [
    { type: 'required', message: 'Affiliation is required.' },
  ];

  cityErrorMessages: ErrorMessage[] = [{ type: 'required', message: 'City is required.' }];

  countryErrorMessages: ErrorMessage[] = [
    { type: 'invalidValue', message: 'Country not recognized.' },
    { type: 'required', message: 'Country is required.' },
  ];

  constructor(
    public dialogRef: MatDialogRef<EditContributorComponent>,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    public allUsersService: AllUsersService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  nothingIsEdited(): boolean {
    let oldData = {
      email: this.data?.contrData?.email,
      firstName: this.data?.contrData?.first_name,
      lastName: this.data?.contrData?.last_name,
      role: this.data.contrData.role,
      affiliations: this.data.contrData.affiliations,
      isCoAuthor: this.data.contrData.is_co_author,
    };
    let newData = {
      email: this.email.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      role: this.roleSelect.value,
      affiliations: this.affiliations.value,
      isCoAuthor: this.isCoAuthor.value,
    };

    if (
      this.affiliations.value.length == 0 ||
      (!this.affiliations.value.some(
        (v: any) => v.affiliation.length > 0 && v.city.length > 0 && v.country.length > 0
      ) &&
        newData.role != 'author')
    ) {
      this.affiliations.clear();
    }

    return JSON.stringify(oldData) == JSON.stringify(newData);
  }

  formSubmitDisabled(): boolean {
    return this.nothingIsEdited() || !this.editUserForm.valid;
  }

  ngOnInit(): void {
    this.allUsersService.getCollaboratorsRoles().subscribe({
      next: (res: any) => {
        this.roleOptions = res.data.filter((r: any) => !r.is_hidden);
        if (this.data.contrData.access == 'Owner') {
          this.isOwner = true;
        }
        this.roleSelect.setValue(this.data.contrData.role);
        this.data.contrData.affiliations.forEach((affiliation) => {
          this.affiliations.push(this.getAffiliationGroup(affiliation));
        });
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (this.data.contrData.access == 'Owner') {
          this.isOwner = true;
        }
      },
    });
  }

  openRemoveDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '532px',
      data: {
        title: 'Remove Contributor',
        content: 'Are you sure you want to remove this person from the article?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.confirmed) {
        this.removeCollaborator();
      }
    });
  }

  removeCollaborator() {
    this.dialogRef.close({ edited: true, removed: true });
  }

  removeAffiliation(index: number) {
    this.affiliations.removeAt(index);
  }

  addAffiliation() {
    this.affiliations.push(this.getAffiliationGroup());
  }

  editCollaborator() {
    if (this.roleSelect.value != 'author' && this.roleSelect.value != 'author_commenter') {
      this.isCoAuthor.setValue(false);
    }

    this.dialogRef.close({
      edited: true,
      role: this.roleSelect.value,
      email: this.email.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      isCoAuthor: this.isCoAuthor.value,
      affiliations:
        this.roleSelect.value == 'author'
          ? this.affiliations.value.filter((x) => {
              return !(
                (!x.affiliation || x.affiliation.length == 0) &&
                (!x.country || x.country.length == 0) &&
                (!x.city || x.city.length == 0)
              );
            })
          : [],
    });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }
}
