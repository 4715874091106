import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';

@Injectable()
export class CasbinInterceptor implements HttpInterceptor {
  constructor(
    private sharedService: ServiceShare,
    private snackBar: SnackbarService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.sharedService.EnforcerService || !this.sharedService.EnforcerService.loadedPolicies) {
      return next.handle(request);
    }
    const unauthenticatedObservable: Observable<HttpEvent<unknown>> = new Observable((sub) => {
      this.snackBar.error(
        "You don't have permission and cannot access this information or do this action."
      );
      sub.next(
        new HttpResponse({
          body: {
            message: 'Not authentucated.',
            status: 404,
            url: request.url,
          },
        })
      );
    });
    if (request.url.endsWith('/layouts') || request.url.endsWith('/citation-styles')) {
      let urlParts = request.url.split('/');
      let casbinobj = '/' + urlParts[urlParts.length - 1];
      return this.sharedService.EnforcerService.enforceAsync(casbinobj, request.method).pipe(
        mergeMap((access) => {
          if (access) {
            return next.handle(request);
          } else {
            return unauthenticatedObservable;
          }
        })
      );
    } else if (/articles\/items\/uuid\/\S+$/.test(request.url)) {
      return this.sharedService.EnforcerService.enforceAsync(
        '/articles/items/*',
        request.method
      ).pipe(
        mergeMap((access) => {
          if (access) {
            return next.handle(request);
          } else {
            return unauthenticatedObservable;
          }
        })
      );
    } else if (
      request.url.endsWith('/references/definitions') ||
      request.url.endsWith('/articles/items')
    ) {
      let urlParts = request.url.split('/');
      let casbinobj = `/${urlParts[urlParts.length - 2]}/${urlParts[urlParts.length - 1]}`;
      return this.sharedService.EnforcerService.enforceAsync(casbinobj, request.method).pipe(
        mergeMap((access) => {
          if (access) {
            return next.handle(request);
          } else {
            return unauthenticatedObservable;
          }
        })
      );
    } else if (
      /\/layouts\/[^\/\s]+$/.test(request.url) ||
      /\/citation-styles\/[^\/\s]+$/.test(request.url)
    ) {
      let urlParts = request.url.split('/');
      let casbinobj = `/${urlParts[urlParts.length - 2]}/${urlParts[urlParts.length - 1]}`;
      return this.sharedService.EnforcerService.enforceAsync(casbinobj, request.method).pipe(
        mergeMap((access) => {
          if (access) {
            return next.handle(request);
          } else {
            return unauthenticatedObservable;
          }
        })
      );
    } else if (
      /\/references\/definitions\/[^\/\s]+$/.test(request.url) ||
      /\/articles\/items\/[^\/\s]+$/.test(request.url) ||
      /\/articles\/sections\/[^\/\s]+$/.test(request.url)
    ) {
      let urlParts = request.url.split('/');
      let casbinobj = `/${urlParts[urlParts.length - 3]}/${
        urlParts[urlParts.length - 2]
      }/${urlParts[urlParts.length - 1]}`;
      return this.sharedService.EnforcerService.enforceAsync(casbinobj, request.method).pipe(
        mergeMap((access) => {
          if (access) {
            return next.handle(request);
          } else {
            return unauthenticatedObservable;
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
