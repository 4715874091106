import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { TreeService } from '@app/editor/meta-data-tree/tree-service/tree.service';
import { articleSection } from '@app/editor/utils/interfaces/articleSection';
import { HttpClient } from '@angular/common/http';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { customSecInterface } from '../funder-section/funder-section.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/shared/material.module';
import { MatFormioModule } from '@app/formio-angular-material/angular-material-formio.module';
import { ArphaButtonComponent2 } from '@app/layout/widgets/arpha-button/arpha-button.component';
import { ChacklistModule } from '../tree-checklist/tree-checklist.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-taxon-section',
  templateUrl: './taxon-section.component.html',
  styleUrls: ['./taxon-section.component.scss'],
})
export class TaxonSectionComponent implements OnInit, customSecInterface {
  @Input() onSubmit!: (data: any) => Promise<any>;
  @Output() onSubmitChange = new EventEmitter<(data: any) => Promise<any>>();

  @Input() section!: articleSection;
  @Output() sectionChange = new EventEmitter<articleSection>();

  @Input() triggerCustomSecSubmit: Subject<any>;
  @Output() triggerCustomSecSubmitChange = new EventEmitter<Subject<any>>();

  searchTaxonControl = new UntypedFormControl();
  infraspecificEpithet = new UntypedFormControl();
  isSelected = false;

  controls: string[];

  render = false;
  classification?: UntypedFormControl;
  rank?: UntypedFormControl;
  kingdom?: UntypedFormControl;
  subkingdom?: UntypedFormControl;
  phylum?: UntypedFormControl;
  subphylum?: UntypedFormControl;

  superclass?: UntypedFormControl;
  class?: UntypedFormControl;
  subclass?: UntypedFormControl;
  superorder?: UntypedFormControl;
  order?: UntypedFormControl;
  suborder?: UntypedFormControl;
  infraorder?: UntypedFormControl;
  superfamily?: UntypedFormControl;
  family?: UntypedFormControl;
  subfamily?: UntypedFormControl;
  tribe?: UntypedFormControl;
  subtribe?: UntypedFormControl;
  genus?: UntypedFormControl;
  subgenus?: UntypedFormControl;
  infraspecific?: UntypedFormControl;
  species?: UntypedFormControl;
  subspecies?: UntypedFormControl;
  variety?: UntypedFormControl;
  form?: UntypedFormControl;
  typeoftreatment?: UntypedFormControl;
  parasiteof?: UntypedFormControl;
  hostof?: UntypedFormControl;
  symbioticwith?: UntypedFormControl;
  feedson?: UntypedFormControl;
  groupone?: UntypedFormControl;
  grouptwo?: UntypedFormControl;
  label: UntypedFormControl;
  otherTreatmentType: UntypedFormControl;

  authorandyear?: UntypedFormControl;
  text1Control?: UntypedFormControl;
  text2Control?: UntypedFormControl;
  taxonauthorsandyear?: UntypedFormControl;

  searchData = [];

  constructor(
    private treeService: TreeService,
    public http: HttpClient,
    private serviceShare: ServiceShare,
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  subscription = new Subscription();
  ngOnInit(): void {
    if (!this.treeService.sectionFormGroups[this.section.sectionID].get('classification')?.value) {
      this.subscription.add(
        this.searchTaxonControl.valueChanges
          .pipe(debounceTime(300), distinctUntilChanged())
          .subscribe((value: any) => {
            if (value?.trim().length > 0 && !this.isSelected) {
              this.searchTaxon(value);
            } else {
              this.isSelected = false;
            }
          })
      );
    } else {
      this.isSelected = true;
    }
    this.classification = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('classification')?.value
    );

    this.rank = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('rank')?.value
    );
    if (this.rank.value) {
      this.isSelected = true;
    }
    this.subkingdom = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('subkingdom')?.value
    );

    this.superclass = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('superclass')?.value
    );
    this.class = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('class')?.value
    );
    this.subclass = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('subclass')?.value
    );
    this.superorder = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('superorder')?.value
    );
    this.order = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('order')?.value
    );
    this.suborder = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('suborder')?.value
    );
    this.infraorder = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('infraorder')?.value
    );
    this.superfamily = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('superfamily')?.value
    );
    this.family = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('family')?.value
    );
    this.subfamily = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('subfamily')?.value
    );
    this.tribe = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('tribe')?.value
    );
    this.subtribe = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('subtribe')?.value
    );
    this.genus = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('genus')?.value
    );
    this.subgenus = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('subgenus')?.value
    );
    this.infraspecific = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('infraspecific')?.value
    );
    this.species = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('species')?.value
    );
    this.subspecies = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('subspecies')?.value
    );
    this.variety = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('variety')?.value
    );
    this.form = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('form')?.value
    );
    this.phylum = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('phylum')?.value
    );
    this.subphylum = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('subphylum')?.value
    );
    this.authorandyear = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('authorandyear')?.value
    );
    this.kingdom = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('kingdom')?.value
    );
    this.typeoftreatment = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('typeoftreatment')?.value ||
        'observation/description'
    );
    this.otherTreatmentType = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('otherTreatmentType')?.value
    );

    this.hostof = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('hostof')?.value
    );
    this.symbioticwith = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('symbioticwith')?.value
    );
    this.feedson = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('feedson')?.value
    );
    this.parasiteof = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('parasiteof')?.value
    );
    this.groupone = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('groupone')?.value
    );
    this.grouptwo = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('grouptwo')?.value
    );
    this.taxonauthorsandyear = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('taxonauthorsandyear')?.value
    );
    this.label = new UntypedFormControl(
      this.treeService.sectionFormGroups[this.section.sectionID].get('label')?.value
    );
    this.infraspecificEpithet.valueChanges.subscribe((value: string) => {
      this.subspecies.setValue(value);
      this.variety.setValue(value);
      this.form.setValue(value);
    });
    if (this.subspecies.value) {
      this.infraspecificEpithet.setValue(this.subspecies.value);
    } else if (this.variety.value) {
      this.infraspecificEpithet.setValue(this.variety.value);
    } else if (this.form) {
      this.infraspecificEpithet.setValue(this.form.value);
    }

    this.controls = [
      'form',
      'variety',
      'species',
      'subspecies',
      'kingdom',
      'subkingdom',
      'phylum',
      'subphylum',
      'superclass',
      'class',
      'subclass',
      'superorder',
      'order',
      'suborder',
      'infraorder',
      'superfamily',
      'family',
      'subfamily',
      'tribe',
      'subtribe',
      'genus',
      'subgenus',
      'label',
    ];

    this.rank.valueChanges.subscribe((value: string) => {
      this.clearFormControls();
    });

    this.triggerCustomSecSubmit.subscribe(() => {
      this.triggerSubmit();
    });

    this.render = true;
  }

  oldSub: Subscription;
  loading = false;
  searchTaxon(value: string) {
    if (this.oldSub) {
      this.oldSub.unsubscribe();
    }
    this.searchData = [];
    this.loading = true;

    this.oldSub = this.httpClient.get(this.config.taxonSearch + `/taxons/${value}`).subscribe({
      next: (responce: any) => {
        this.searchData = responce;
        this.loading = false;
      },
      error: (err: any) => {
        this.isSelected = false;
        this.loading = false;
      },
    });
  }

  isLoading = false;
  select(row: any) {
    this.isLoading = true;
    this.subscription.unsubscribe();
    this.httpClient.get(this.config.taxonSearch + '/taxons/uuid/' + row.taxonid).subscribe({
      next: (result: any) => {
        this.rank.setValue(result.taxonrank);
        if (
          result.taxonrank == 'species' ||
          result.taxonrank === 'subspecies' ||
          result.taxonrank === 'variety' ||
          result.taxonrank === 'form'
        ) {
          this.genus.setValue(result.genericname);
          this.subgenus.setValue(result.infragenericepithet);
          this.species.setValue(result.specificepithet);
          this.subspecies.setValue(result.infraspecificepithet);
          this.variety.setValue(result.infraspecificepithet);
          this.form.setValue(result.infraspecificepithet);
        } else if (result.taxonrank == 'kingdom') {
          this.kingdom.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'subkingdom') {
          this.subkingdom.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'phylum') {
          this.phylum.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'subphylum') {
          this.subphylum.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'superclass') {
          this.superclass.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'class') {
          this.class.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'subclass') {
          this.subclass.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'superorder') {
          this.superorder.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'order') {
          this.order.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'suborder') {
          this.suborder.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'infraorder') {
          this.infraorder.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'superfamily') {
          this.superfamily.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'family') {
          this.family.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'subfamily') {
          this.subfamily.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'tribe') {
          this.tribe.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'subtribe') {
          this.subtribe.setValue(result.scientificname.split(' ')[0]);
        } else if (result.taxonrank == 'genus') {
          this.genus.setValue(result.genericname);
        } else if (result.taxonrank == 'subgenus') {
          this.genus.setValue(result.genericname);
          this.subgenus.setValue(result.infragenericepithet);
        }
        this.authorandyear.setValue(result.scientificnameauthorship);
        this.classification.setValue(result.classification.name);
        this.isLoading = false;
        this.isSelected = true;
      },
      error: (err: any) => {
        console.log(err);
        this.isLoading = false;
      },
    });
    this.oldSub?.unsubscribe();
  }

  displayFn(option: any): string {
    return option ? `${option.scientificname} | ${option.rank} | ${option.nomenclaturalcode}` : '';
  }

  clearFormControls(): void {
    // this.controls.forEach((control: string) => {
    //   if(this[control].value && this[control].value.length > 0) {
    //     this[control].setValue(null);
    //   }
    // })
  }

  addManually() {
    this.isSelected = true;
  }

  async triggerSubmit() {
    if (
      this.rank.value != 'species' &&
      this.rank.value != 'subspecies' &&
      this.rank.value != 'variety' &&
      this.rank.value != 'form'
    ) {
      if (this.rank.value != 'genus') {
        this.genus.setValue(null);
      }
      if (this.rank.value != 'subgenus') {
        this.subgenus.setValue(null);
      }
      this.species.setValue(null);
      this.subspecies.setValue(null);
      this.form.setValue(null);
    }

    let data = {
      classification: this.classification!.value,
      rank: this.rank!.value,
      subkingdom: this.subkingdom!.value,
      subphylum: this.subphylum!.value,
      phylum: this.phylum!.value,
      kingdom: this.kingdom!.value,
      authorandyear: this.authorandyear!.value,
      label: '',

      superclass: this.superclass!.value,
      class: this.class!.value,
      subclass: this.subclass!.value,
      superorder: this.superorder!.value,
      order: this.order!.value,
      suborder: this.suborder!.value,
      infraorder: this.infraorder!.value,
      superfamily: this.superfamily!.value,
      family: this.family!.value,
      subfamily: this.subfamily!.value,
      tribe: this.tribe!.value,
      subtribe: this.subtribe!.value,
      genus: this.genus!.value,
      subgenus: this.subgenus!.value,
      infraspecific: this.infraspecific!.value,
      species: this.species!.value,
      subspecies: this.subspecies!.value,
      variety: this.variety!.value,
      form: this.form!.value,
      typeoftreatment: this.typeoftreatment!.value,
      otherTreatmentType: this.otherTreatmentType.value,
      hostof: this.hostof!.value,
      symbioticwith: this.symbioticwith!.value,
      parasiteof: this.parasiteof!.value,
      feedson: this.feedson!.value,
      groupone: this.groupone!.value,
      grouptwo: this.grouptwo!.value,

      taxonauthorsandyear: this.taxonauthorsandyear!.value,
      taxonTitle: 'TaxonTitle',
    };
    const { taxonTitle, label } = this.treeService.generateTaxonTitle(data);
    data.taxonTitle = taxonTitle;
    if (label) {
      data.label = label;
      this.label.setValue(label);
    }

    this.treeService.sectionFormGroups[this.section.sectionID]
      .get('taxonTitle')
      .setValue(data.taxonTitle);
    this.onSubmit({ data });
  }
}

@Component({
  selector: 'app-taxon-section',
  templateUrl: './taxon-section.component.html',
  styleUrls: ['./taxon-section.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatFormioModule,
    ArphaButtonComponent2,
    ChacklistModule,
    FlexLayoutModule,
  ],
})
export class TaxonSectionComponent2 extends TaxonSectionComponent {}
