import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copied-to-clip-board',
  templateUrl: './copied-to-clip-board.component.html',
  styleUrls: ['./copied-to-clip-board.component.scss'],
})
export class CopiedToClipBoardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
