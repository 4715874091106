import { UntypedFormControl } from '@angular/forms';
import { DOMSerializer, Schema, Node, Fragment } from 'prosemirror-model';
import { EditorState, PluginKey, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Map } from 'yjs';
import { DOMParser } from 'prosemirror-model';
import * as LZString from 'lz-string';

import { ReplaceStep } from 'prosemirror-transform';
import { articleSection } from '../interfaces/articleSection';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { FullSchemaDOMPMSerializer } from '../Schema/filterNodesIfSchemaDefPlugin';

let figuresTimer: NodeJS.Timeout;
let tablesTimer: NodeJS.Timeout;
let endNotesTimer: NodeJS.Timeout;
let suppFilesTimer: NodeJS.Timeout;
let customPropsTimer = debounceF(
  (customPropsObj: any, customSectionProps: any) =>
    customSectionProps.set('customPropsObj', customPropsObj),
  'set customPropsObj'
);
let mathMapTimer = debounceF(
  (mathObj: any, mathMap: any) => mathMap.set('dataURLObj', mathObj),
  'set customPropsObj'
);

export const getHtmlFromFragment = (fr: Fragment, DOMPMSerializer: DOMSerializer) => {
  const nodes = [];
  //@ts-ignore
  fr.content.forEach((node: any) => {
    if (
      node?.type?.name != 'figures_nodes_container' &&
      node?.type?.name != 'tables_nodes_container'
    ) {
      nodes.push(node);
    }
  });
  const fragment = Fragment.from(nodes);
  let HTMLnodeRepresentation = DOMPMSerializer.serializeFragment(fragment);
  let temp = document.createElement('div');
  temp.appendChild(HTMLnodeRepresentation);
  return temp.innerHTML;
};

export const updateControlsAndFigures = (
  schema: Schema,
  figuresMap: Map<any>,
  mathMap: Map<any>,
  editorContainers: {
    [key: string]: {
      editorID: string;
      containerDiv: HTMLDivElement;
      editorState: EditorState;
      editorView: EditorView;
      dispatchTransaction: any;
    };
  },
  rerenderFigures: (citats: any) => any,
  YjsHistoryKey: PluginKey,
  interpolateTemplate: any,
  sharedService: ServiceShare,
  GroupControl?: any,
  section?: articleSection
) => {
  let DOMPMSerializer = DOMSerializer.fromSchema(schema);
  let DOMPMParser = DOMParser.fromSchema(schema);

  let sectionTreeTitleUpdateMetas: any;
  return (trs: Transaction[], oldState: EditorState, newState: EditorState) => {
    try {
      let customPropsObj = sharedService.YdocService!.customSectionProps?.get('customPropsObj');
      let setcustomProp = false;
      let tr1 = newState.tr;
      // return value whe r = false the transaction is canseled
      trs.forEach((transaction) => {
        if (transaction.steps.length > 0 || transaction.getMeta('emptyTR')) {
          newState.doc.nodesBetween(0, newState.doc.nodeSize - 2, (node, pos, parent) => {
            //@ts-ignore
            node.parent = parent;

            if (node.type.name == 'block_figure') {
              let figures = figuresMap.get('ArticleFigures');
              let figure = figures[node.attrs.figure_id];
              if (figure) {
                let hasChange = false;
                node.content.forEach((node1, offset, index) => {
                  if (node1.type.name == 'figure_descriptions_container') {
                    node1.content.forEach((node2) => {
                      if (node2.type.name == 'figure_description') {
                        let figureDescriptionHtml = getHtmlFromFragment(
                          node2.content!,
                          DOMPMSerializer
                        );
                        if (figure?.description && figure.description != figureDescriptionHtml) {
                          hasChange = true;
                          figure.description = figureDescriptionHtml;
                        }
                      } else if (
                        figure?.components &&
                        node2.type.name == 'figure_component_description' &&
                        node2.childCount >= 2
                      ) {
                        const componentDesc = getHtmlFromFragment(
                          node2.content.child(1).content,
                          DOMPMSerializer
                        );
                        if (
                          figure.components[+node2.attrs.component_number] &&
                          figure.components[+node2.attrs.component_number].description !=
                            componentDesc
                        ) {
                          hasChange = true;
                          figure.components[+node2.attrs.component_number].description =
                            componentDesc;
                        }
                      } else if (
                        figure?.components &&
                        node2.type.name == 'figure_component_description' &&
                        node2.childCount == 1
                      ) {
                        const componentDesc = getHtmlFromFragment(
                          node2.content.child(0).content,
                          DOMPMSerializer
                        );
                        if (
                          figure.components[+node2.attrs.component_number] &&
                          figure.components[+node2.attrs.component_number].description !=
                            componentDesc
                        ) {
                          hasChange = true;
                          figure.components[+node2.attrs.component_number].description =
                            componentDesc;
                        }
                      }
                    });
                  }
                });
                if (hasChange && !sharedService.oldVersion) {
                  const versionsLength = sharedService.YdocService.articleVersions.length;

                  if (figure.editedInVersion !== versionsLength) {
                    figure.editedInVersion = versionsLength;
                  }

                  figuresMap.set('ArticleFigures', JSON.parse(JSON.stringify(figures)));
                }
              }
            } else if (node.type.name == 'block_table') {
              clearTimeout(tablesTimer);
              tablesTimer = setTimeout(() => {
                let tables = sharedService.YdocService.tablesMap
                  ? sharedService.YdocService.tablesMap.get('ArticleTables')
                  : undefined;
                let table = tables[node.attrs.table_id];
                if (table) {
                  let hasChange = false;
                  node.content.forEach((node1, offset, index) => {
                    if (node1.type.name == 'table_header_container') {
                      node1.content.forEach((node2) => {
                        if (node2.type.name == 'table_description') {
                          let tableDescriptionHtml = getHtmlFromFragment(
                            node2.content,
                            FullSchemaDOMPMSerializer
                          );
                          if (
                            table.header !== tableDescriptionHtml &&
                            !tableDescriptionHtml.startsWith('<h')
                          ) {
                            hasChange = true;
                            table.header = tableDescriptionHtml;
                          }
                        }
                      });
                    } else if (node1.type.name == 'table_footer_container') {
                      node1.content.forEach((node2) => {
                        if (node2.type.name == 'table_description') {
                          let tableFooterHtml = getHtmlFromFragment(
                            node2.content,
                            FullSchemaDOMPMSerializer
                          );
                          if (table.footer !== tableFooterHtml) {
                            hasChange = true;
                            table.footer = tableFooterHtml;
                          }
                        }
                      });
                    } else if (node1.type.name == 'table_content') {
                      let tableContentHtml = getHtmlFromFragment(
                        node1.content,
                        FullSchemaDOMPMSerializer
                      );
                      if (!table.canvasImage) {
                        const tableContent = tableContentHtml;
                        if (tableContent !== table.content) {
                          hasChange = true;
                          table.content = tableContent;
                        }
                      }
                    }
                  });
                  if (hasChange && !sharedService.oldVersion) {
                    const versionsLength = sharedService.YdocService.articleVersions.length;
                    if (table.editedInVersion !== versionsLength) {
                      table.editedInVersion = versionsLength;
                    }
                    sharedService.YdocService.tablesMap.set('ArticleTables', tables);
                  }
                }
              }, 1000);
            } else if (node.type.name == 'block_end_note') {
              let endNotes = sharedService.YdocService.endNotesMap
                ? sharedService.YdocService.endNotesMap.get('endNotes')
                : undefined;
              let endNote = endNotes[node.attrs.end_note_id];
              if (endNote) {
                let hasChange = false;
                node.nodesBetween(0, node.content.size, (node) => {
                  if (
                    node.attrs.formControlName?.includes('endNote') ||
                    node.attrs.controlPath?.includes('endNote')
                  ) {
                    const endNoteContent = getHtmlFromFragment(node.content, DOMPMSerializer);
                    if (endNote.endNote != endNoteContent) {
                      hasChange = true;
                      endNote.endNote = endNoteContent;
                    }
                  }
                });
                if (
                  hasChange &&
                  sharedService.compareObjects(
                    sharedService.YdocService.endNotesMap.get('endNotes'),
                    endNotes
                  )
                ) {
                  sharedService.YdocService.endNotesMap.set(
                    'endNotes',
                    JSON.parse(JSON.stringify(endNotes))
                  );
                }
              }
            } else if (node.type.name == 'block_supplementary_file') {
              let supplementaryFiles = sharedService.YdocService.supplementaryFilesMap
                ? sharedService.YdocService.supplementaryFilesMap.get('supplementaryFiles')
                : undefined;
              let supplementaryFile = supplementaryFiles[node.attrs.supplementary_file_id];
              if (supplementaryFile) {
                let hasChange = false;
                node.nodesBetween(0, node.content.size, (node, pos, par, i) => {
                  if (node.attrs.controlPath?.includes('supplementaryFileTitle')) {
                    if (supplementaryFile.title != node.textContent) {
                      hasChange = true;
                      supplementaryFile.title = node.textContent;
                    }
                  } else if (node.attrs.controlPath?.includes('supplementaryFileAuthors')) {
                    if (supplementaryFile.authors != node.textContent) {
                      hasChange = true;
                      supplementaryFile.authors = node.textContent;
                    }
                  } else if (node.attrs.controlPath?.includes('supplementaryFileDataType')) {
                    if (supplementaryFile.data_type != node.textContent) {
                      hasChange = true;
                      supplementaryFile.data_type = node.textContent;
                    }
                  } else if (
                    node.attrs.controlPath?.includes('supplementaryFileBriefDescription')
                  ) {
                    let brief_description = getHtmlFromFragment(node.content, DOMPMSerializer);
                    if (supplementaryFile.brief_description != brief_description) {
                      hasChange = true;
                      supplementaryFile.brief_description = brief_description;
                    }
                  } else if (
                    node.marks.length > 0 &&
                    node.marks.some((mark) => mark.type.name == 'link')
                  ) {
                    supplementaryFile.url = node.marks.find(
                      (mark) => mark.type.name == 'link'
                    ).attrs.href;
                  }
                });
                if (
                  hasChange &&
                  sharedService.compareObjects(
                    sharedService.YdocService.supplementaryFilesMap.get('supplementaryFiles'),
                    supplementaryFiles
                  )
                ) {
                  sharedService.YdocService.supplementaryFilesMap.set(
                    'supplementaryFiles',
                    JSON.parse(JSON.stringify(supplementaryFiles))
                  );
                }
              }
            }
            if (
              (node.attrs.customPropPath && node.attrs.customPropPath != '') ||
              node.marks.filter(
                (mark) => mark.attrs.customPropPath != '' && mark.attrs.customPropPath
              ).length > 0
            ) {
              if (!customPropsObj[section!.sectionID]) {
                customPropsObj[section!.sectionID] = {};
              }
              let customPropPath = node.attrs.customPropPath;
              if (
                node.marks.filter(
                  (mark) => mark.attrs.customPropPath != '' && mark.attrs.customPropPath
                ).length > 0
              ) {
                customPropPath = node.marks.find(
                  (mark) => mark.attrs.customPropPath != '' && mark.attrs.customPropPath
                ).attrs.customPropPath;
              }
              if (node.textContent.trim() != customPropsObj[section.sectionID][customPropPath]) {
                customPropsObj[section!.sectionID][customPropPath] = node.textContent.trim();
                setcustomProp = true;
              }
            }

            if (section?.title?.name == '[MM] Materials') {
              if (node.attrs.controlPath) {
                const [key, sectionID] = node.attrs.controlPath.split('&');
                if (customPropsObj[sectionID]) {
                  if (customPropsObj[sectionID][key] !== node.textContent) {
                    customPropsObj[sectionID][key] = node.textContent;
                    setcustomProp = true;
                  }
                }
              }
            }
            if (
              section &&
              GroupControl &&
              (node.attrs.formControlName ||
                node.marks.filter(
                  (mark) => mark.attrs.formControlName != '' && mark.attrs.formControlName
                ).length > 0) &&
              GroupControl[section!.sectionID]
            ) {
              // validation for the formCOntrol
              try {
                const fg = sharedService.TreeService.sectionFormGroups[section!.sectionID];
                let controlPath = node.attrs.controlPath;
                if (
                  node.marks.filter(
                    (mark) => mark.attrs.formControlName != '' && mark.attrs.formControlName
                  ).length > 0
                ) {
                  controlPath = node.marks.find(
                    (mark) => mark.attrs.formControlName != '' && mark.attrs.formControlName
                  ).attrs.controlPath;
                }
                if (fg) {
                  const control = fg.get(controlPath) as UntypedFormControl;
                  if (control) {
                    if (
                      //@ts-ignore
                      (control.componentType && control.componentType == 'textarea') ||
                      controlPath == 'sectionTreeTitle'
                    ) {
                      let html = getHtmlFromFragment(node.content, DOMPMSerializer);
                      if (node.attrs.menuType) {
                        //@ts-ignore
                        if (!control.componentProps) {
                          //@ts-ignore
                          control.componentProps = {};
                        }
                        //@ts-ignore
                        control.componentProps.menuType = node.attrs.menuType;
                      }
                      control.setValue(html, { emitEvent: true });
                    } else {
                      control.setValue(node.textContent, { emitEvent: true });
                    }
                    control.updateValueAndValidity();

                    if (
                      control.invalid &&
                      node.attrs.invalid !== 'true' &&
                      node.type.name !== 'text'
                    ) {
                      tr1 = tr1.setNodeMarkup(pos, node.type, { ...node.attrs, invalid: 'true' });
                    } else if (
                      control.valid &&
                      node.attrs.invalid !== 'false' &&
                      node.type.name !== 'text'
                    ) {
                      if (node.attrs.invalid !== 'false') {
                        tr1 = tr1.setNodeMarkup(pos, node.type, {
                          ...node.attrs,
                          invalid: 'false',
                        });
                      }
                    }
                  }
                }
              } catch (error) {
                console.error(error);
              }
            }
          });
        }
      });
      if (setcustomProp) {
        customPropsTimer(customPropsObj, sharedService.YdocService!.customSectionProps);
      }
      sharedService.YjsHistoryService.stopLessItemsCapturePrevention();
      return tr1;
    } catch (e) {
      console.error(e);
    }
  };
};

export const preventDragDropCutOnNoneditablenodes = (
  figuresMap: Map<any>,
  mathMap: Map<any>,
  rerenderFigures: (citats: any) => any,
  sectionID: string,
  sharedService: ServiceShare,
  options?: any
) => {
  return (transaction: Transaction, state: EditorState) => {
    try {
      if (
        transaction.steps.length &&
        transaction.steps[0] instanceof ReplaceStep &&
        options?.path == 'tableContent'
      ) {
        if (
          (transaction.steps[0].from == 0 && transaction.steps[0].to == 2) ||
          (transaction.steps[0].from == 0 && transaction.steps[0].to == 0)
        ) {
          return false;
        }
        if (transaction.steps[0].from == 1 && transaction.steps[0].slice.size == 2) {
          transaction.replaceRangeWith(
            0,
            transaction.doc.nodeSize - 2,
            state.schema.nodes.form_field.create({
              allowedTags: options.allowedTags,
              styling: 'min-height: 80px; width: 100%;',
            })
          );
        }
      }
      if (transaction.steps.length > 0) {
        transaction.steps.forEach((step) => {
          if (step instanceof ReplaceStep) {
            //@ts-ignore
            let replacingSlice = state.doc.slice(step.from, step.to);
            replacingSlice.content.nodesBetween(0, replacingSlice.size, (node, pos, parent) => {
              if (
                node.marks.filter((mark) => {
                  return mark.type.name == 'citation';
                }).length > 0
              ) {
                let citatMark = node.marks.filter((mark) => {
                  return mark.type.name == 'citation';
                })[0];
                let citatID = citatMark.attrs.citateid;
              } else if (node.type.name == 'math_inline' || node.type.name == 'math_display') {
                if (!transaction.getMeta('y-sync$')) {
                  let mathObj = mathMap?.get('dataURLObj');
                  let math_id = node.attrs.math_id;
                  mathObj[math_id] = undefined;
                  mathMapTimer(mathObj, mathMap);
                }
              }
            });
          }
        });
      }
      //@ts-ignore
      let meta = transaction.meta;

      if (meta.uiEvent || Object.keys(meta).includes('cut') || Object.keys(meta).includes('drop')) {
        let noneditableNodesOnDropPosition = false;
        let dropIsInTable = false;
        let stateSel: any = state.selection;
        //@ts-ignore
        let trSel = transaction.curSelection;
        //@ts-ignore
        let headFormField: Node;
        let anchorFormField: Node;
        stateSel.$head.path.forEach((element: number | Node) => {
          if (element instanceof Node) {
            if (
              element.attrs.contenteditableNode == 'false' ||
              element.attrs.contenteditableNode === false
            ) {
              noneditableNodesOnDropPosition = true;
            }
            if (element.type.name == 'form_field') {
              headFormField = element;
            }
            if (
              element.type.name == 'table_cell' ||
              element.type.name == 'table_row' ||
              element.type.name == 'table'
            ) {
              dropIsInTable = true;
            }
          }
        });
        stateSel.$anchor.path.forEach((element: number | Node) => {
          if (element instanceof Node) {
            if (
              element.attrs.contenteditableNode == 'false' ||
              element.attrs.contenteditableNode === false
            ) {
              noneditableNodesOnDropPosition = true;
            }
            if (element.type.name == 'form_field') {
              anchorFormField = element;
            }
            if (
              element.type.name == 'table_cell' ||
              element.type.name == 'table_row' ||
              element.type.name == 'table'
            ) {
              dropIsInTable = true;
            }
          }
        });
        if (meta.uiEvent == 'cut' || Object.keys(meta).includes('cut')) {
          //@ts-ignore
          if (anchorFormField !== headFormField) {
            return false;
          }
          if (noneditableNodesOnDropPosition) {
            return false;
          }
        } else if (meta.uiEvent == 'drop' || Object.keys(meta).includes('drop')) {
          let dropPosPath: Array<number | Node> = trSel.$anchor.path;

          let index = dropPosPath.length - 1;
          while (index >= 0 && !dropIsInTable) {
            let arrayElement = dropPosPath[index];
            if (arrayElement instanceof Node) {
              if (
                arrayElement.type.name == 'table_cell' ||
                arrayElement.type.name == 'table_row' ||
                arrayElement.type.name == 'table'
              ) {
                dropIsInTable = true;
              }
            }
            index--;
          }
          let trSelFormField: Node;
          trSel.$anchor.path.forEach((element: number | Node) => {
            if (element instanceof Node) {
              if (
                element.attrs.contenteditableNode == 'false' ||
                element.attrs.contenteditableNode === false
              ) {
                noneditableNodesOnDropPosition = true;
              }
              if (element.type.name == 'form_field') {
                trSelFormField = element;
              }
            }
          });
          //@ts-ignore
          if (anchorFormField !== headFormField || !trSelFormField) {
            return false;
          }
          if (noneditableNodesOnDropPosition || dropIsInTable) {
            return false;
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
    return true;
  };
};

export let elementOnWhichClickShouldNoteBeHandled = [
  'update-data-reference-button',
  'move-citable-item-up-button',
  'move-citable-item-down-button',
  /* 'edit-citable-item-button', */
  'delete-citable-item-button',
  'reference-citation-pm-buttons',
  'update-data-reference-img',
  'move-citable-item-up-img',
  'move-citable-item-down-img',
  'edit-citable-item-img',
  'delete-citable-item-img',
  'citat-menu-context-delete-citat-btn',
  'citat-menu-context',
];

//handle right click on citats
export const handleClickOn = (serviceShare: ServiceShare) => {
  return (
    view: EditorView,
    pos: number,
    node: Node,
    nodePos: number,
    e: MouseEvent,
    direct: boolean
  ) => {
    if (node.type.name == 'example' || view.state.doc.nodeAt(pos)?.type?.name == 'example') {
      e.stopPropagation();
      return false;
    }

    if (
      e.target &&
      e.target instanceof HTMLElement &&
      e.target.className &&
      e.target.className
        .split(' ')
        .some((cls) => elementOnWhichClickShouldNoteBeHandled.includes(cls))
    ) {
      return true;
    } else {
      return false;
    }
  };
};

function debounceF(func: any, a: string) {
  let timeout: any;
  return (data: any, map: any) => {
    const later = () => {
      console.log(a);
      clearTimeout(timeout);
      func(data, map);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, 500);
  };
}
