import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { uuidv4 } from 'lib0/random';
import { EditorView } from 'prosemirror-view';
import { TextSelection } from 'prosemirror-state';
import { Mark } from 'prosemirror-model';

import { ProsemirrorEditorsService } from '../../services/prosemirror-editors.service';
import { Map } from 'yjs';
import { YdocService } from '../../services/ydoc.service';
import { AuthService } from '@core/services/auth.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { fakeUser } from '@app/core/services/comments/comments-interceptor.service';
import { ContributorsApiService } from '@app/core/services/comments/contributors-api.service';
import { EditCommentDialogComponent } from '../edit-comment-dialog/edit-comment-dialog.component';
import { AskBeforeDeleteComponent } from '@app/editor/dialogs/ask-before-delete/ask-before-delete.component';
import {
  commentData,
  commentYdocSave,
  ydocComment,
} from '../../utils/commentsService/commentMarksHelpers';

export function getDate(date: number) {
  let d = new Date(+date);
  let timeString =
    d.getHours() +
    ':' +
    (`${d.getMinutes()}`.length == 1 ? `0${d.getMinutes()}` : d.getMinutes()) +
    ' ' +
    ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][d.getDay() - 1] +
    ' ' +
    d.getDate() +
    '/' +
    d.getMonth() +
    '/' +
    d.getFullYear();
  return timeString;
}

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() comment?: commentData;
  originalComment?: commentData;

  selectedComment;

  @Input() doneRenderingCommentsSubject?: Subject<any>;
  @Output() doneRenderingCommentsSubjectChange = new EventEmitter<Subject<any>>();

  @ViewChild('content') elementView: ElementRef | undefined;
  @ViewChild('input') input: ElementRef | undefined;
  @ViewChild('ReplyDiv') ReplyDiv: ElementRef | undefined;

  @Output() selected = new EventEmitter<boolean>();

  replyInputDisplay = false;
  initialShowMore = false;
  repliesShowMore: boolean[] = [];
  moreLessBtnView: any = {};
  MAX_CONTENT_WIDTH = 290;
  contentWidth: number = this.MAX_CONTENT_WIDTH;
  commentsMap?: Map<any>;
  userComment?: commentYdocSave;
  mobileVersion: boolean;
  filteredUsers: fakeUser[];

  replyFormControl = new UntypedFormControl('');
  showAutoComplete = false;
  activeReply = false;

  previewMode;
  constructor(
    public authService: AuthService,
    public ydocService: YdocService,
    public sharedDialog: MatDialog,
    private prosemirrorEditorService: ProsemirrorEditorsService,
    private contributorsApiService: ContributorsApiService,
    public sharedService: ServiceShare,
    private router: Router,
    public dialog: MatDialog,
    private changeDetection: ChangeDetectorRef
  ) {
    this.previewMode = sharedService.ProsemirrorEditorsService!.previewArticleMode;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    if (this.ydocService.editorIsBuild) {
      this.commentsMap = this.ydocService.getCommentsMap();
    }
    this.ydocService.ydocStateObservable.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event == 'docIsBuild') {
        this.commentsMap = this.ydocService.getCommentsMap();
      }
    });
    this.mobileVersion = prosemirrorEditorService.mobileVersion;
  }

  currUserId: string;

  threadOfComments: commentData[] = [];
  hasThread = false;

  ngOnInit(): void {
    this.selectedComment = 0;
    this.originalComment = this.comment;
    this.insertThreadComments(false);

    this.userComment = this.commentsMap?.get(this.comment!.commentAttrs.id) || {
      initialComment: undefined,
      commentReplies: undefined,
    };
    this.currUserId = this.ydocService.currUser.id;

    this.prosemirrorEditorService.mobileVersionSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        // data == true => mobule version
        this.mobileVersion = data;
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.doneRenderingCommentsSubject.next('rendered');
    }, 10);
    this.sharedService.CommentsService.ydocCommentsChangeSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((commentsObj) => {
        if (commentsObj) {
          let ydocCommentInstance = commentsObj[this.comment.commentAttrs.id];
          this.checkIfCommentHasChanged(ydocCommentInstance);
        } else {
          this.insertThreadComments(true);
        }
        this.changeDetection.detectChanges();
      });
    this.sharedService.CommentsService.commentsChangeSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.insertThreadComments(true);
        this.changeDetection.detectChanges();
      });
    this.userComment?.commentReplies.forEach((comment, index) => {
      this.repliesShowMore[index] = false;
    });
    let timeout: NodeJS.Timeout;
    this.sharedService.CommentsService.lastSelectedCommentSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((comment) => {
        if (this.ydocService.curUserAccess && this.ydocService.curUserAccess == 'Reader') {
          return;
        }

        if (this.threadOfComments.find((c) => c.commentAttrs.id == comment.commentId)) {
          this.ReplyDiv
            ? ((this.ReplyDiv.nativeElement as HTMLDivElement).style.display = 'block')
            : undefined;
          this.selected.emit(true);
        } else {
          this.ReplyDiv
            ? ((this.ReplyDiv.nativeElement as HTMLDivElement).style.display = 'none')
            : undefined;
          this.selected.emit(false);
        }

        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.insertThreadComments(true);
        }, 200);
      });
  }

  commentIsChangedInYdoc() {
    this.doneRenderingCommentsSubject.next('change_in_comments_in_ydoc');
  }

  showMoreLessClick() {
    setTimeout(() => {
      this.doneRenderingCommentsSubject.next('show_more_less_click');
    }, 30);
  }

  checkIfCommentHasChanged(commentInYdoc: commentYdocSave) {
    let changed = false;
    if (commentInYdoc) {
      if (commentInYdoc.initialComment.comment != this.userComment.initialComment.comment) {
        changed = true;
      }
      if (commentInYdoc.commentReplies.length != this.userComment.commentReplies.length) {
        changed = true;
      } else {
        commentInYdoc.commentReplies.forEach((reply, index) => {
          let localReply = this.userComment.commentReplies[index];
          if (localReply.comment != reply.comment) {
            changed = true;
          }
        });
      }
    } else {
      // comment deleted
      changed = true;
    }
    if (changed && commentInYdoc) {
      this.userComment = JSON.parse(JSON.stringify(commentInYdoc));

      setTimeout(() => {
        this.commentIsChangedInYdoc();
      }, 20);
    }
    // setTimeout(() => {
    //   this.insertThreadComments(false);
    // }, 200)
  }

  insertThreadComments(shouldReturnOriginal: boolean) {
    this.originalComment =
      this.sharedService.CommentsService.commentsObj[this.originalComment?.commentAttrs.id];
    if (this.originalComment) {
      this.hasThread = !!this.originalComment.threadComments.length;
      const currUser = this.sharedService.YdocService.currUser;
      const collaborators = this.sharedService.YdocService.getCollaborators().filter(
        (c: any) => c.id != currUser?.id
      );
      let idsThatShouldBeHidden = collaborators
        .filter(
          (c: any) =>
            c.hide_my_comments_from_user?.includes(currUser?.auth_role) ||
            c.hide_my_comments_from_user?.includes(currUser?.id)
        )
        .map((c: any) => c.id);

      if (!this.hasThread) {
        this.threadOfComments = [this.originalComment];
        this.comment = this.originalComment;
      } else {
        if (this.sharedService.CommentsService.showResolved) {
          this.threadOfComments = [
            this.originalComment,
            ...this.originalComment.threadComments.filter(
              (c) => !idsThatShouldBeHidden.includes(c.commentAttrs.userid)
            ),
          ];
          this.comment = this.threadOfComments[this.selectedComment]
            ? this.threadOfComments[this.selectedComment]
            : this.threadOfComments[0];
        } else {
          if (this.originalComment.commentAttrs.resolved == 'false') {
            this.threadOfComments = [
              this.originalComment,
              ...this.originalComment.threadComments
                .filter((c) => c.commentAttrs.resolved == 'false')
                .filter((c) => !idsThatShouldBeHidden.includes(c.commentAttrs.userid)),
            ];
          } else {
            this.threadOfComments = [
              ...this.originalComment.threadComments
                .filter((c) => c.commentAttrs.resolved == 'false')
                .filter((c) => !idsThatShouldBeHidden.includes(c.commentAttrs.userid)),
            ];
          }
          this.comment = this.threadOfComments[this.selectedComment]
            ? this.threadOfComments[this.selectedComment]
            : this.threadOfComments[0] || this.originalComment;
        }
      }
      this.userComment = this.commentsMap?.get(this.comment!.commentAttrs.id) || {
        initialComment: undefined,
        commentReplies: undefined,
      };
    }
  }

  selectComment() {
    if (this.comment) {
      let view =
        this.sharedService.ProsemirrorEditorsService.editorContainers[this.comment.section]
          .editorView;
      let actualComment: commentData;
      let allComments = this.sharedService.CommentsService.commentsObj;
      this.sharedService.CommentsService.selectedThreadComment = undefined;

      Object.keys(allComments).forEach((commentid) => {
        let com = allComments[commentid];
        if (com && com.commentAttrs.id == this.comment.commentAttrs.id) {
          actualComment = com;
        } else if (com) {
          const comment = com.threadComments.find(
            (c) => c.commentAttrs.id == this.comment.commentAttrs.id
          );
          if (comment) {
            actualComment = comment;
            this.sharedService.CommentsService.selectedThreadComment =
              actualComment.commentAttrs.id;
          }
        }
      });
      if (actualComment) {
        view.focus();
        view.dispatch(
          view.state.tr
            .setSelection(
              new TextSelection(
                view.state.doc.resolve(actualComment.pmDocStartPos),
                view.state.doc.resolve(actualComment.pmDocStartPos)
              )
            )
            .setMeta('selected-comment', true)
            .scrollIntoView()
        );
      }

      if (this.threadOfComments.length > 1) {
        setTimeout(() => {
          this.sharedService.CommentsService.commentsChangeSubject.next('change');
          this.changeDetection.detectChanges();
        }, 500);
      }
    }
  }

  onDelete(view: EditorView, commentId: string) {
    let state = view.state;
    let docSize = state.doc.content.size;
    let from: number, to: number;
    let markForRemove: Mark;

    state.doc.nodesBetween(0, docSize, (node, pos, parent) => {
      let comment = node?.marks.find((c) => c.attrs.id == commentId);

      if (comment) {
        markForRemove = comment;
        if (!from) {
          from = pos;
        }
        to = pos += node.nodeSize;
      }
    });
    view.focus();
    view.dispatch(state.tr.removeMark(from, to, markForRemove));

    setTimeout(() => {
      this.commentsMap?.delete(this.comment?.commentAttrs.id);
      this.sharedService.CommentsService.selectedThreadComment = undefined;
      this.sharedService.CommentsService.updateAllComments();
    }, 500);
  }

  resolveComment(action: boolean, event: Event) {
    event.stopPropagation();

    const view =
      this.sharedService.ProsemirrorEditorsService.editorContainers[this.comment.section]
        .editorView;
    let state = view.state;
    const docSize = state.doc.content.size;
    let from: number, to: number;
    let markForRemove: Mark;

    state.doc.nodesBetween(0, docSize, (node, pos, parent) => {
      let comment = node?.marks.find((c) => c.attrs.id == this.comment.commentAttrs.id);

      if (comment) {
        markForRemove = comment;
        if (!from) {
          from = pos;
        }
        to = pos += node.nodeSize;
      }
    });
    let tr = state.tr.removeMark(from, to, markForRemove);
    view.dispatch(tr);

    // Re-fetch state
    state = view.state;

    const newMark = state.schema.marks[markForRemove.type.name].create({
      ...markForRemove.attrs,
      resolved: `${action}`,
    });
    tr = state.tr.addMark(from, to, newMark);
    view.dispatch(tr);
    this.comment.commentAttrs.resolved = newMark.attrs.resolved;
    this.comment.resolved = newMark.attrs.resolved;

    setTimeout(() => {
      const commentIds = this.sharedService.CommentsService.getCommentsIds(this.comment);
      const commentContainer = document.getElementsByClassName(commentIds)[0] as HTMLDivElement;
      if (commentContainer) {
        if (action && !this.sharedService.CommentsService.showResolved) {
          if (!this.hasThread) {
            // commentContainer.setAttribute("resolved", `${action}`);
            this.threadOfComments = [this.originalComment];
            this.comment = this.originalComment;
            setTimeout(() => {
              commentContainer.style.opacity = '0';
            }, 500);
          } else {
            this.threadOfComments = [
              ...this.threadOfComments.filter(
                (c) => c.commentAttrs.id != this.comment.commentAttrs.id
              ),
            ];
            this.selectedComment = 0;
            this.comment = this.threadOfComments[this.selectedComment];
            this.selectComment();
          }
        } else {
          setTimeout(() => {
            commentContainer.style.opacity = '1';
          }, 500);
        }
      }
      this.sharedService.CommentsService.updateAllComments();
      this.changeDetection.detectChanges();
    }, 200);
  }

  deleteComment(showConfirmDialog: boolean, comment: any) {
    let viewRef =
      this.sharedService.ProsemirrorEditorsService.editorContainers[this.comment.section]
        .editorView;
    if (showConfirmDialog) {
      let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
        data: { objName: comment, type: 'comment' },
        panelClass: 'ask-before-delete-dialog',
      });
      dialogRef.afterClosed().subscribe((data: any) => {
        if (data) {
          this.onDelete(viewRef, this.comment.commentAttrs.id);
        }
      });
      return;
    }
    this.onDelete(viewRef, this.comment.commentAttrs.id);
  }

  deleteReply(id: string, reply: string) {
    let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      data: { objName: reply, type: 'reply' },
      panelClass: 'ask-before-delete-dialog',
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.comment = this.threadOfComments[this.selectedComment];
        let commentData: commentYdocSave = this.commentsMap?.get(this.comment?.commentAttrs.id);
        commentData.commentReplies.splice(
          commentData.commentReplies.findIndex((el) => {
            return el.id == id;
          }),
          1
        );
        this.commentsMap?.set(this.comment?.commentAttrs.id, commentData);
        this.userComment = commentData;
        this.insertThreadComments(false);
      }
    });
  }

  showReplyFocusHandle(replyDiv: HTMLDivElement, autocomplete) {
    this.activeReply = true;
    autocomplete.showResults();
  }

  hideReplyBlurHandle(replyDiv: HTMLDivElement) {
    if (this.replyFormControl.value == '') {
      this.activeReply = false;
    }
  }

  clickOutsideHandler(event, autocomplete) {
    if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'MAT-ICON') {
      autocomplete.hideResults();
    }
  }

  cancelReplyBtnHandle(replyDiv: HTMLDivElement) {
    this.input.nativeElement.rows = 1;
    this.replyFormControl.setValue('');
    this.activeReply = false;
  }

  editComment(id: string, comment: string) {
    this.comment = this.threadOfComments[this.selectedComment];
    let commentData: commentYdocSave = this.commentsMap?.get(this.comment?.commentAttrs.id);
    let commentContent: any = comment;
    const dialogRef = this.sharedDialog.open(EditCommentDialogComponent, {
      panelClass: 'comment-edit-dialog',
      width: '582px',
      data: {
        comment: commentContent,
        type: 'comment',
        actualCommentId: this.comment,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let newcommentContent = result;
      if (result && newcommentContent != commentContent) {
        commentData.initialComment.comment = newcommentContent;
        this.userComment = commentData;
        this.commentsMap?.set(this.comment?.commentAttrs.id, commentData);
        this.changeDetection.detectChanges();
        this.contentWidth = this.elementView?.nativeElement.firstChild.offsetWidth;
        this.moreLessBtnView[this.comment!.commentAttrs.id] =
          this.contentWidth >= this.MAX_CONTENT_WIDTH;
      }
    });
  }

  editReply(id: string, comment: string) {
    this.comment = this.threadOfComments[this.selectedComment];
    let commentData: commentYdocSave = this.commentsMap?.get(this.comment?.commentAttrs.id);
    let commentContent: any = comment;
    const dialogRef = this.sharedDialog.open(EditCommentDialogComponent, {
      panelClass: 'comment-edit-dialog',
      width: '582px',
      data: { comment: commentContent, type: 'comment', actualCommentId: this.comment },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let newcommentContent = result;
      if (result && newcommentContent != commentContent) {
        commentData.commentReplies.forEach((userComment, index, array) => {
          if (userComment.id == id) {
            array[index].comment = newcommentContent;
          }
        });
        this.userComment = commentData;
        this.commentsMap?.set(this.comment?.commentAttrs.id, commentData);
        this.changeDetection.detectChanges();
        this.contentWidth = this.elementView?.nativeElement.firstChild.offsetWidth;
        this.moreLessBtnView[this.comment!.commentAttrs.id] =
          this.contentWidth >= this.MAX_CONTENT_WIDTH;
      }
    });
  }

  getDate = getDate;

  commentReplyBtnHandle = (replyDiv: HTMLDivElement) => {
    if (!this.replyFormControl.value) {
      return;
    }

    this.comment = this.threadOfComments[this.selectedComment];
    let commentData: commentYdocSave = this.commentsMap?.get(this.comment?.commentAttrs.id);
    let commentContent;
    let userCommentId = uuidv4();
    let commentDate = Date.now();

    commentContent = this.replyFormControl.value;

    let userComment = {
      id: userCommentId,
      comment: commentContent,
      userData: {
        ...this.prosemirrorEditorService.userInfo.data,
        userColor: this.prosemirrorEditorService.userInfo.color.userColor,
        userContrastColor: this.prosemirrorEditorService.userInfo.color.userContrastColor,
      },
      date: commentDate,
    };
    commentData.commentReplies.push(userComment);
    this.commentsMap?.set(this.comment?.commentAttrs.id, commentData);
    this.userComment = commentData;
    this.replyFormControl.setValue('');
    this.activeReply = false;
    setTimeout(() => {
      this.input.nativeElement.rows = 1;
      this.doneRenderingCommentsSubject.next('replay_rerender');
    }, 400);
  };

  lastAddBoxHeight = 0;
  adjustRows() {
    const textarea: HTMLTextAreaElement = this.input?.nativeElement;
    if (textarea) {
      if (this.lastAddBoxHeight != textarea.getBoundingClientRect().height) {
        this.lastAddBoxHeight = textarea.getBoundingClientRect().height;
        this.showMoreLessClick();
      }
      textarea.rows = 1;

      while (textarea.rows < 20 && textarea.scrollHeight > textarea.clientHeight) {
        textarea.rows += 1;
      }
    }
  }

  getYdocComment(id: string) {
    return this.sharedService.CommentsService.commentsMap.get(id);
  }

  onTabChange(index: number) {
    this.selectedComment = index;
    this.comment = this.threadOfComments[this.selectedComment];
    this.userComment = this.commentsMap?.get(this.comment!.commentAttrs.id) || {
      initialComment: undefined,
      commentReplies: undefined,
    };
    this.changeDetection.detectChanges();
    setTimeout(() => {
      this.sharedService.CommentsService.commentsChangeSubject.next('change');
    }, 200);
  }

  trackById(index: number, item: commentData) {
    return item.commentAttrs.id;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
