import { Component, ElementRef, inject, Inject, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { articleSection } from '@app/editor/utils/interfaces/articleSection';
import { Subscription } from 'rxjs';
import * as PModel from 'prosemirror-model';
import { ImportJatsService } from '../importAsJatsXML.service';
import { sectionChooseData, willBeMoreThan4Levels } from '@app/editor/utils/articleBasicStructure';
import { ChooseSectionComponent } from '@app/editor/dialogs/choose-section/choose-section.component';
import { debounceTime } from 'rxjs/operators';
import { updateYFragment } from '../../../../../y-prosemirror-src/plugins/sync-plugin.js';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';

@Component({
  selector: 'app-import-wizard-dialog',
  templateUrl: './import-wizard-modal.component.html',
  styleUrls: ['./import-wizard-modal.component.scss'],
})
export class ImportWizardDialogComponent implements OnDestroy {
  @ViewChild('ProsemirrorEditor', { read: ElementRef })
  ProsemirrorEditor?: ElementRef;

  sections: {
    doc: PModel.Node;
    sectionTitle: string;
    secID: string;
    level?: number;
    parsedSecTitle?: string;
  }[];
  articleSections: string[] = this.sharedService.YdocService.articleStructureMap.get(
    'articleSectionsStructureFlat'
  );
  choosedSections: any = {};
  optionsSource: any[];

  shouldMerge = false;

  subscription = new Subscription();

  isLoading = false;
  initialContent = { initial: true, title: 'Original Content' };
  constructor(
    public dialog: MatDialog,
    public sharedService: ServiceShare,
    public importJatsService: ImportJatsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sectionsContent: {
        [key: string]: {
          doc: PModel.Node | null;
          sectionTitle: string;
          secID: string;
          level?: number;
          parsedSecTitle?: string;
        };
      };
      allJatsSections: { title: string; section: Element; secID?: string }[];
    },
    private dialogRef: MatDialogRef<ImportWizardDialogComponent>,
    private snackBar: SnackbarService
  ) {
    this.optionsSource = [this.initialContent, ...this.data.allJatsSections];
    this.sections = Object.values(this.data.sectionsContent);
  }

  createFormControl(sectionID: string, parsedSectionTitle: string) {
    if (!this[sectionID]) {
      const parsedSection = this.data.allJatsSections.find(
        (data) => data.title == parsedSectionTitle && (!data.secID || data.secID == sectionID) // Compare the section id if there are more jats sections with the same title
      );
      const parsed = [parsedSection ? parsedSection : this.initialContent];
      this[sectionID] = new UntypedFormControl();
      this.subscription.add(
        (this[sectionID] as UntypedFormControl).valueChanges.pipe(debounceTime(700)).subscribe(
          async (
            values: {
              title: string;
              section: HTMLElement;
              initial?: boolean;
            }[]
          ) => {
            const { section } = this.findSection(sectionID);
            const { hTag } = this.sharedService.TreeService.getNodeLevel(section);
            this.data.sectionsContent[section.sectionID].doc =
              await this.importJatsService.parseSectionsFromModal(
                values.map((val) => val.section),
                section,
                hTag,
                this.shouldPreserve,
                this.data.sectionsContent[section.sectionID].parsedSecTitle
                  ? this.data.sectionsContent[section.sectionID].parsedSecTitle
                  : section.title.label?.match(/<[^>]*>/g)
                    ? section.title.label.replace(/<[^>]*>/g, '').trim()
                    : section.title.label,
                !!values.find((val) => val.initial)
              );
          }
        )
      );
      this[sectionID].setValue(parsed);
      return this[sectionID];
    }
    return this[sectionID];
  }

  findSection(id: string) {
    const sectionContent = this.data.sectionsContent[id].doc;
    const section = this.sharedService.YdocService.articleSectionsMap.get(id);
    return { sectionContent, section };
  }

  titles: string[] = [];
  titleFormControl = new UntypedFormControl('');
  lastPreviewData: any;
  async handlePreviewOption(
    event: { choosed: any[]; sectionID: string; titles: string[] },
    titleChange?: boolean
  ) {
    this.lastPreviewData = event;
    const { section } = this.findSection(event.sectionID);
    this.titles = event.titles.map((t) =>
      t == 'Original Title'
        ? `${
            section.title.label?.match(/<[^>]*>/g)
              ? section.title.label.replace(/<[^>]*>/g, '').trim()
              : section.title.label
          }`
        : t
    );
    if (
      section.title.name != 'Taxon' &&
      section.title.name != '[MM] Materials' &&
      section.title.name != '[MM] External Links'
    ) {
      if (!titleChange) {
        //@ts-ignore
        this.titleFormControl.setValue(
          this.data.sectionsContent[event.sectionID].parsedSecTitle || section.title.label
        );
      } else {
        this.data.sectionsContent[event.sectionID].parsedSecTitle = this.titleFormControl.value;
      }
    }
    if (!this.titleFormControl.value) {
      //@ts-ignore
      this.titleFormControl.setValue(
        this.data.sectionsContent[event.sectionID].parsedSecTitle || section.title.label
      );
    }
    const { hTag } = this.sharedService.TreeService.getNodeLevel(section);
    this.data.sectionsContent[section.sectionID].doc =
      await this.importJatsService.parseSectionsFromModal(
        event.choosed.map((val) => val.section),
        section,
        hTag,
        this.shouldPreserve,
        this.data.sectionsContent[section.sectionID].parsedSecTitle
          ? this.data.sectionsContent[section.sectionID].parsedSecTitle
          : section.title.label?.match(/<[^>]*>/g)
            ? section.title.label.replace(/<[^>]*>/g, '').trim()
            : section.title.label,
        !!event.choosed.find((val) => val.initial)
      );
    this.createEditor(this.data.sectionsContent[section.sectionID].doc);
  }

  createEditor(doc: PModel.Node) {
    this.ProsemirrorEditor.nativeElement.innerHTML = '';
    this.sharedService.ProsemirrorEditorsService.renderPreviewEditor(
      this.ProsemirrorEditor?.nativeElement,
      doc
    );
  }

  shouldPreserve = false;
  preserveIntervalOption() {
    this.shouldPreserve = !this.shouldPreserve;
  }

  renderInitialContent = false;

  addNewSection() {
    let articleSections =
      this.sharedService.YdocService.articleData.layout.template.sections.filter((data: any) => {
        let secIsNotAtMax =
          this.sharedService.TreeService.checkIfNodeIsAtMaxInParentListWithBESection(data);
        return secIsNotAtMax;
      });
    let mainSection: sectionChooseData[] = [];
    articleSections.forEach((sec) => {
      mainSection.push({
        id: sec.id,
        name:
          sec.settings && sec.settings.label && sec.settings.label.length > 0
            ? sec.settings.label
            : sec.name,
        secname: sec.name,
        version: sec.version,
        version_date: sec.version_date,
        source: 'template',
        pivot_id: sec.pivot_id,
        template: sec,
      });
    });
    const dialogRef = this.dialog.open(ChooseSectionComponent, {
      width: '563px',
      panelClass: 'choose-namuscript-dialog',
      data: { templates: mainSection, sectionlevel: 0 },
    });
    dialogRef.afterClosed().subscribe((result: sectionChooseData) => {
      if (result) {
        this.isLoading = true;
        let newSection: articleSection;
        if (!willBeMoreThan4Levels(0, result.template)) {
          newSection = this.sharedService.TreeService.addNodeAtPlaceChange(
            'parentList',
            result.template,
            'end'
          );
          const getAllSections = (children: articleSection[], level: number) => {
            children.forEach((sec) => {
              this.sections.push({
                doc: this.sharedService.ProsemirrorEditorsService.editorContainers[sec.sectionID]
                  .editorView.state.doc,
                sectionTitle: sec.title.label,
                secID: sec.sectionID,
                level,
              });
              this.data.sectionsContent[sec.sectionID] = {
                doc: this.sharedService.ProsemirrorEditorsService.editorContainers[sec.sectionID]
                  .editorView.state.doc,
                sectionTitle: sec.title.label,
                secID: sec.sectionID,
                level,
              };
              if (sec.children) {
                getAllSections(sec.children, level + 1);
              }
            });
          };
          setTimeout(() => {
            this.articleSections = this.sharedService.YdocService.articleStructureMap.get(
              'articleSectionsStructureFlat'
            );
            this.isLoading = false;
            this.sections.push({
              doc: this.sharedService.ProsemirrorEditorsService.editorContainers[
                newSection.sectionID
              ].editorView.state.doc,
              sectionTitle: newSection.title.label,
              secID: newSection.sectionID,
              level: 2,
            });
            this.data.sectionsContent[newSection.sectionID] = {
              doc: this.sharedService.ProsemirrorEditorsService.editorContainers[
                newSection.sectionID
              ].editorView.state.doc,
              sectionTitle: newSection.title.label,
              secID: newSection.sectionID,
              level: 2,
            };
            getAllSections(newSection.children, 3);
          }, 1000);
        } else {
          this.snackBar.warn('Adding this subsection will exceed the maximum levels of the tree.');
        }
      }
    });
  }

  submitImport() {
    const editors = this.sharedService.ProsemirrorEditorsService.editorContainers;
    this.importJatsService.addCitableElementsToEditor();
    this.importJatsService.inviteContributors();
    this.sharedService.ProsemirrorEditorsService.spinSpinner();

    setTimeout(() => {
      this.sections.forEach((data) => {
        const section = this.sharedService.YdocService.articleSectionsMap.get(
          data.secID
        ) as articleSection;
        const { doc } = this.data.sectionsContent[data.secID];

        if (
          section.title.name == '[AM] Title' &&
          this.sharedService.titleControl.value == 'Untitled' &&
          doc.textContent?.trim()
        ) {
          this.sharedService.titleControl.setValue(
            doc.textContent.replace(this.sharedService.escapeHtmlTags, '')
          );
        }

        if (doc) {
          const editor = editors[data.secID];
          if (editor) {
            const xmlFragment = this.sharedService.ProsemirrorEditorsService.getXmlFragment(
              undefined,
              data.secID
            );
            updateYFragment(this.sharedService.YdocService.ydoc, xmlFragment, doc, new Map());

            //@ts-ignore
            if (
              section.formIOSchema?.optional &&
              'content' in doc.content &&
              Array.isArray(doc.content?.content) &&
              doc.content?.content?.length > 1
            ) {
              this.sharedService.TreeService.showHideSection(data.secID, 'block');
            }
          }
        }
      });
      setTimeout(() => {
        this.sharedService.updateCitableElementsViewsAndCites();
        this.sharedService.ProsemirrorEditorsService.stopSpinner();
        // this.importJatsService.setEmptyCitableElements();

        // this.sharedService.TaxonService.markTaxonsWithBackendService() - disabled for now...
      }, 5000);
    }, 150);
    this.dialogRef.close(true);
  }

  cancelImport() {
    this.dialogRef.close(undefined);
    this.importJatsService.setEmptyCitableElements();
    this.sharedService.ProsemirrorEditorsService.stopSpinner();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
